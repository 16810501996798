import { Endpoints } from "../Endpoints";
import { usePostRequest } from "./usePostRequest";

export enum ChangePasswordResponseError {
  Expired = "Expired",
  Internal = "Internal",
}

export const useChangePasswordRequest = () => {
  const post = usePostRequest();

  return async (
    newPassword: string,
    token: string
  ): Promise<ChangePasswordResponse> => {
    const request: ChangePasswordRequest = {
      password: newPassword,
    };

    const response = await post(Endpoints.changePassword, request, token);

    if (!response) {
      return { error: ChangePasswordResponseError.Internal };
    }

    if (response.status === 403) {
      return { error: ChangePasswordResponseError.Expired };
    }

    if (!response.ok) {
      return { error: ChangePasswordResponseError.Internal };
    }

    return {};
  };
};

interface ChangePasswordRequest {
  password: string;
}

interface ChangePasswordResponse {
  error?: ChangePasswordResponseError;
}
