export const VideoSection = () => {
  return (
    <div
      className="relative px-5 md:px-20 pb-5 md:pb-20 pt-10 flex flex-col"
      style={{
        backgroundImage: "url(" + require("../../assets/signup_bg.png") + ")",
      }}
    >
      <span className="text-3xl font-bold text-[#FFD966] mb-4 self-center underline">
        Trailer
      </span>

      <div className="flex flex-row w-full min-h-0 justify-center">
        <div className="relative max-w-[1280px] flex-1">
          <div
            className="absolute -left-12 md:-left-24 -top-48 bg-contain bg-no-repeat z-10 -scale-x-75 scale-y-75 md:-scale-100 md:scale-y-100"
            style={{
              backgroundImage:
                "url(" + require("../../assets/gazebra.png") + ")",
              width: 744 / 4,
              height: 1086 / 4,
            }}
          />
          <div className="relative h-0 overflow-hidden pb-[56.25%] ">
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              title="Youtube-Video of ChronoCraft"
              frameBorder="0"
              allowFullScreen
              src="https://www.youtube.com/embed/V0osFViz43E?feature=oembed&autoplay=0&wmode=opaque&loop=1&controls=0&mute=1&rel=0&modestbranding=0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
