export const ImageTitle = (props: {
  alt: string;
  src: string;
  className?: string;
}) => {
  return (
    <img
      className={`self-start max-w-full max-h-10 ${props.className ?? ""}`}
      src={props.src}
      alt={props.alt}
    />
  );
};
