import * as React from "react";

import {
  ResetPasswordResponseError,
  useResetPasswordRequest,
} from "../../requests/useResetPasswordRequest";
import { useTrackOnUnmount } from "../../tools/useTrackOnUnmount";
import { FormFields } from "../common/FormFields";
import { FormInput } from "../common/FormInput";
import { ModalProps } from "../common/Modal";
import { SimpleModal } from "../common/SimpleModal";
import { useTrackEvent } from "../utility/useTrackEvent";
type Props = ModalProps;

export const ResetPasswordModal = (props: Props) => {
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState<
    "start" | "inProgress" | "success" | "error"
  >("start");
  const [errorMessage, setErrorMessage] = React.useState("");
  const trackEvent = useTrackEvent();
  useTrackOnUnmount("ResetPassword_Closed");
  const resetPassword = useResetPassword();

  const isValid = !!email && /^(.+)@(.+)\.(.+)$/.test(email);

  function submit(): void {
    trackEvent("ResetPassword_SubmitClicked");

    setStatus("inProgress");

    resetPassword(email).then((result) => {
      if (result === "success") {
        setStatus("success");
      } else if (result === "notFound") {
        setStatus("error");
        setErrorMessage("Account does not exist!");
      } else {
        setStatus("error");
        setErrorMessage("Failed to reset password (internal error).");
      }
    });
  }

  return (
    <SimpleModal
      {...props}
      title="Reset Password"
      buttonDisabled={
        status !== "success" && (!isValid || status === "inProgress")
      }
      buttonText={status === "success" ? "Ok" : "Reset"}
      onButtonClick={status === "success" ? props.onExit : submit}
      errorMessage={status === "error" ? errorMessage : undefined}
    >
      {status === "success" ? (
        <p className="text-light">An email has been sent to {email}!</p>
      ) : (
        <FormFields>
          <FormInput
            name={"email"}
            placeholder={"email"}
            value={email}
            onChange={(value) => setEmail(value)}
            readonly={status === "inProgress"}
            isFirst
            isLast
            errorMessage={status === "error" ? errorMessage : undefined}
          />
        </FormFields>
      )}
    </SimpleModal>
  );
};

const useResetPassword = () => {
  const doResetPassword = useResetPasswordRequest();

  return async (email: string) => {
    const result = await doResetPassword(email);

    if (result.error) {
      switch (result.error) {
        case ResetPasswordResponseError.NOT_FOUND:
          return "notFound";
        case ResetPasswordResponseError.INTERNAL:
          return "internalError";
      }
    }

    return "success";
  };
};
