import { useCallback } from "react";
import { Endpoints } from "../Endpoints";
import { usePostRequest } from "../requests/usePostRequest";
import { AccountInfo } from "../state/AccountInfo";
import { useAccountInfoContext } from "../state/AccountInfoContext";
import { useAuthContext } from "../state/AuthContext";

export const useRefetchtAccountInfo = () => {
  const post = usePostRequest();
  const { setInfo } = useAccountInfoContext();
  const { state } = useAuthContext();
  const authenticated = state.type === "authenticated";

  return useCallback(async () => {
    if (!authenticated) {
      console.error("Error fetching account info: not authenticated");
      return;
    }

    const response = await post(Endpoints.accountInfo);

    if (!response || !response.ok) {
      console.error("Error fetching account info: ", response?.status);
      return;
    }

    const json = await response.json();
    const info = json as AccountInfo;

    setInfo(info);
  }, [authenticated, post, setInfo]);
};
