export function mapObjectValues<TKey extends string, TOld, TNew>(
  values: { [key in TKey]: TOld },
  mapper: (value: TOld, key: TKey) => TNew
): { [key in TKey]: TNew } {
  const result: { [key in string]: TNew } = {};

  Object.keys(values).forEach((key) => {
    result[key] = mapper(values[key as TKey], key as TKey);
  });

  return result as { [key in TKey]: TNew };
}
