export const DefaultRoutes = {
    landingPage: "/",
    signUp: "/signup",
    signUpSuccess: "/signup/success",
    account: "/account",
    resetPassword: "/resetPassword/:token",
    privacyPolicy: "/privacy",
    conditions: "/conditions",
    billingInput: "/billingInput/:token",
    blog: "/blog",
  };
  