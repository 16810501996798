import * as React from "react";
import AriaModal, { AriaModalProps } from "react-aria-modal";
import { ModalStyles } from "./ModalStyles";
import { ModalTitle } from "./ModalTitle";

export type ModalProps = Omit<AriaModalProps, "titleId" | "titleText">;

type Props = ModalProps & {
  title: string;
  className?: string;
  Footer?: JSX.Element;
};

export const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
  ...props
}) => {
  return (
    <AriaModal
      getApplicationNode={getApplicationNode}
      verticallyCenter
      underlayStyle={ModalStyles.underlay}
      titleText={title}
      titleId={undefined}
      {...props}
    >
      <div
        className={`relative w-[90vw] relative rounded-md border-solid border border-gray-700 flex flex-col shadow-default ${
          props.className ?? ""
        }`}
        style={{
          backgroundImage: `url(${require("../../assets/signup_bg.png")})`,
        }}
      >
        <div className="bg-gray-300 overflow-hidden rounded-t-md p-4 text-center">
          <ModalTitle>{title}</ModalTitle>
        </div>
        <div className="p-10 flex flex-col">{children}</div>
        <div className="rounded-b-md px-10 pb-4 pt-0" />
      </div>
      {props.Footer}
    </AriaModal>
  );
};

function getApplicationNode(): HTMLElement {
  return document.getElementById("root") as HTMLElement;
}
