import * as React from "react";

interface Props {
  className?: string;
  error?: boolean;
  style?: React.CSSProperties;
  isFirst?: boolean;
  isLast?: boolean;
  isLeft?: boolean;
  isRight?: boolean;
  isPayment?: boolean;
}

export const InputWrapper = ({
  children,
  error,
  className = "",
  style = {},
  isFirst,
  isLast,
  isLeft,
  isRight,
  isPayment
}: React.PropsWithChildren<Props>) => {
  const errorColor = "rgb(255, 50, 50)";
  let boxShadow: string | undefined = undefined;
  if (error) {
    if (isFirst && !isLast) {
      boxShadow = `0px 1px ${errorColor}`;
      if (!isPayment) {
        boxShadow += `, inset 0px -1px ${errorColor}`;
      }
    } else if (isLast && !isFirst) {
      boxShadow = `0px -1px ${errorColor}`;
      if (isLeft) {
        boxShadow += `,1px 0px ${errorColor}`;
      } else if (isRight) {
        boxShadow += `,-1px 0px ${errorColor}`;
      }
    } else if (!isLast && !isFirst) {
      boxShadow = `0px 1px ${errorColor}, 0px -1px ${errorColor}`;
    }
  }

  return (
    <div
      className={"border-solid focus-within:bg-[#172F66] bg-[#172F66] flex relative items-center flex-grow overflow-hidden input-wrapper " + className}
      style={{
        boxShadow: boxShadow,
        // borderTopLeftRadius: isFirst ? 5 : 0,
        // borderTopRightRadius: isFirst ? 5 : 0,
        // borderBottomLeftRadius: isLast && !isRight ? 5 : 0,
        // borderBottomRightRadius: isLast && !isLeft ? 5 : 0,
        borderTopWidth: isFirst ? 1 : 0,
        borderBottomWidth: isLast ? 1 : 0,
        borderLeftWidth: isRight ? 0 : 1,
        borderRightWidth: isLeft ? 0 : 1,
        borderRightColor: error ? errorColor : "#000000",
        borderBottomColor: error ? errorColor : "#000000",
        borderTopColor: error ? errorColor : "#000000",
        borderLeftColor: error ? errorColor : "#000000",
        ...style
      }}
    >
      {children}
    </div>
  );
};
