import { createContext, useContext, useMemo, useState } from "react";

type AuthState =
  | { type: "loading" }
  | { type: "authenticated"; token: string }
  | { type: "unauthenticated" };
type AuthContextType = {
  state: AuthState;
  setState: (state: AuthState) => void;
};

const AuthContext = createContext<AuthContextType>({
  state: { type: "loading" },
  setState: () => {},
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const useAuthToken = () => {
  const { state } = useAuthContext();
  return state.type === "authenticated" ? state.token : null;
};

export const AuthContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<AuthState>({ type: "loading" });

  const value: AuthContextType = useMemo(() => {
    return {
      state,
      setState,
    };
  }, [state, setState]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
