import * as React from "react";
import { Page } from "../Page";
import { Heading } from "../common/Heading";
import { Paragraph } from "../common/Paraprah";
import { Title } from "../common/Title";

export const ConditionsPage = () => {
  return (
    <Page>
      <div className="m-8 md:m-16">
        <Title>{"Terms & Conditions (5/3/2020)"}</Title>
        <Paragraph>
          (Words such as ‘we’, ‘our’, and ‘ChronoCraft’ refer to ChronoCraft LLC
          as a company. Words such as ‘customer’, ‘you’, ‘you’re’ and ‘your’
          refer to the person accepting this agreement.)
        </Paragraph>

        <Heading>1. Product Expectations</Heading>
        <Paragraph>
          1.1 If you have signed up for the ChronoCraft subscription, we will do
          our best to ensure you are given access to the online game servers as
          soon as your payment clears, however we do not guarantee that you will
          have access right away since our servers and website go down regularly
          for maintenance.
        </Paragraph>
        <Paragraph>
          1.2 The online game servers and website will cease functioning for
          extended periods of time due to maintenance work from our providers
          and internally from ChronoCraft staff and contractors to update the
          game or website and fix issues.
        </Paragraph>
        <Paragraph>
          1.3 Any future features of our products listed on our website, social
          media, any ChronoCraft official accounts online, or advertised are not
          guarantees that they will exist. Future features are subject to
          customer interest and business viability.
        </Paragraph>
        <Paragraph>
          1.4 Any existing features are subject to change or removal at any time
          for any reason.
        </Paragraph>
        <Paragraph>
          1.5 We do not guarantee that our products will be compatible with your
          hardware.
        </Paragraph>
        <Paragraph>
          1.6 We may change the prices of our products at any time and for any
          reason. We will inform our customers at least 14 days in advance via
          email to the contact email they have provided.
        </Paragraph>
        <Paragraph>
          1.7 When you connect to our game servers you will be receiving digital
          content to be downloaded and streamed as part of the digital
          product/service.
        </Paragraph>
        <Paragraph>
          1.8 We are not associated with Mojang or Minecraft. Our products may
          require you to have a Minecraft paid account in order to sign up for
          our subscription. We do not work for Mojang or Minecraft. Any of our
          creations concerning Minecraft code are in accordance with Minecraft’s
          EULA. We do not sell our client software (otherwise called a “mod”).
          Our client software is free to download. We sell access to
          ChronoCraft’s online servers so customers can play the “mod” with each
          other. We do not guarantee that the client software will work as well
          without access to our servers.
        </Paragraph>

        <Heading>
          2. Refund Policy *(For EU customers, this section does not apply)
        </Heading>
        <Paragraph>
          2.1 All refunds are subject to our discretion. There are no guarantees
          that you will receive a refund.
        </Paragraph>
        <Paragraph>
          2.2 If you are unable to use our product for the entire period you
          have subscribed for, we may offer a refund, but it is not a guarantee
          and is at our discretion.
        </Paragraph>
        <Paragraph>
          2.3 If you’d like to request a refund, you can do so by emailing the
          support email listed on our website. Include your reason why you are
          requesting the refund in the email.
        </Paragraph>
        <Paragraph>
          2.4 Any Refund will be given within 30 days after we have accepted
          your request for the refund.
        </Paragraph>

        <Heading>
          3. EU Refund Policy *(This section only applies to EU customers)
        </Heading>
        <Paragraph>
          3.1 You may request a refund within 14 days for any and no reason at
          all.
        </Paragraph>
        <Paragraph>
          3.2 If you want to request a refund, email our support email listed on
          our website. While not required you may leave your reason for the
          refund in the email.
        </Paragraph>
        <Paragraph>
          3.3 Since our product is a digital product the moment you connect to
          our game servers, you are waiving your right of withdrawal and refund.
          When you connect to our game servers, our contractual performance has
          begun. This is because you are downloading and streaming digital
          content from our servers. Even though you are waiving your right to
          withdrawal and refund, we may still decide to give you a refund at our
          discretion. To request a refund after waving your right to withdrawal
          and refund, you will need to email our support email listed on our
          site with your reasons why you would like a refund.
        </Paragraph>

        <Heading>4. Use of Our Product/Services</Heading>
        <Paragraph>
          4.1 Do not use foul language whether in text or in images.
          Pornographic material or suggestive language is strictly prohibited
          in-game or on any of our platforms. Such behavior may result in a
          temporary or permanent chat or server ban.
        </Paragraph>
        <Paragraph>
          4.2 Do not ask other customers for certain personal info, like phone
          numbers, login credentials, age, credit card info, addresses and real
          names. Such behavior may result in a temporary or permanent chat or
          server ban.
        </Paragraph>
        <Paragraph>
          4.3 Using any bug exploits in the game to get an advantage may result
          in a temporary or permanent chat or server ban. You must report any
          such bugs that provide unfair advantages in game.
        </Paragraph>
        <Paragraph>
          4.4 Any hacking or use of modifications like x-ray textures, movement
          hacks, etc. to get an unfair advantage will result in a permanent ban.
        </Paragraph>
        <Paragraph>
          4.5 Any unique creations of ChronoCraft such as characters, story, 3D
          models and the like, are exclusive intellectual property and not to be
          distributed. If you plan on using any of our IP for commercial
          purposes, please contact our support email listed on our site to
          request permission.
        </Paragraph>

        <Heading>5. Acceptance of Terms and Conditions</Heading>
        <Paragraph>
          5.1 By downloading our software you are agreeing to these terms and
          conditions.
        </Paragraph>
        <Paragraph>
          5.2 We additionally require you to agree to these terms and conditions
          using a checkbox in the sign up popup and are required to do so before
          signing up for a subscription.
        </Paragraph>
        <Paragraph>
          5.3 By accepting these terms and conditions you are also consenting to
          any changes made to the terms and conditions in the future, as long as
          it is legal to do so and only the parts of the changes that are legal
          if other changes are out of compliance in your respective country. We
          may also require you to accept the new terms and conditions manually
          from time to time and we may instead also issue notices of the changes
          via the contact email the customer has provided.
        </Paragraph>
      </div>
    </Page>
  );
};
