export async function safeFetch(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response | undefined> {
    try {
      return await fetch(input, init);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
  