import { useParams } from "react-router-dom";
import { Page } from "../Page";
import { useState } from "react";
import { FormFields } from "../common/FormFields";
import { FormInput } from "../common/FormInput";
import { Button } from "../common/Button";
import {
  ChangePasswordResponseError,
  useChangePasswordRequest,
} from "../../requests/useChangePasswordRequest";
import { Paragraph } from "../common/Paraprah";

export const ResetPasswordPage = () => {
  const [success, setSuccess] = useState(false);

  return (
    <Page>
      <div className="p-8 md:p-16 flex flex-col items-center">
        {success ? (
          <Paragraph>Your password has been changed!</Paragraph>
        ) : (
          <ResetPasswordForm onSuccess={() => setSuccess(true)} />
        )}
      </div>
    </Page>
  );
};

const ResetPasswordForm = (props: { onSuccess: () => void }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState<
    "start" | "inProgress" | "success" | "error"
  >("start");
  const [errorMessage, setErrorMessage] = useState("");

  const changePassword = useResetPassword();

  const isInProgress = status === "inProgress";
  const isValidForm = password === confirmPassword && password.length > 0;

  const handleSubmit = async () => {
    setStatus("inProgress");

    const result = await changePassword(password);
    switch (result) {
      case "success":
        setStatus("success");
        props.onSuccess();
        return;
      case "expired":
        setStatus("error");
        setErrorMessage("The link to reset the password expired!");
        return;
      default:
      case "internalError":
        setStatus("error");
        setErrorMessage("Failed to reset password (internal error).");
        return;
    }
  };

  return (
    <div className="flex flex-col w-[300px]">
      <FormFields title="Reset Password">
        <FormInput
          placeholder={"New password"}
          value={password}
          onChange={(value) => setPassword(value)}
          type={"password"}
          readonly={isInProgress}
          isFirst
        />
        <FormInput
          placeholder={"Confirm password"}
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
          type={"password"}
          readonly={isInProgress}
          isLast
        />
      </FormFields>
      <Button disabled={!isValidForm || isInProgress} onClick={handleSubmit}>
        Change
      </Button>
      {errorMessage && (
        <span className="mt-2 self-center text-error">{errorMessage}</span>
      )}
    </div>
  );
};

const useResetPassword = () => {
  const { token } = useParams();
  const changePassword = useChangePasswordRequest();

  return async (password: string) => {
    if (!token) {
      return "internalError";
    }

    const result = await changePassword(password, `Bearer ${token}`);

    if (result.error) {
      switch (result.error) {
        case ChangePasswordResponseError.Expired:
          return "expired";
        case ChangePasswordResponseError.Internal:
        default:
          return "internalError";
      }
    }

    return "success";
  };
};
