export const ModalTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <h2
      className="font-bold text-2xl bg-gray-500 text-transparent m-0 bg-clip-text select-none"
      style={{
        textShadow: "1px 1px 2px rgba(255, 255, 255, 0.5)",
      }}
    >
      {children}
    </h2>
  );
};
