import { Endpoints } from "../Endpoints";
import { useAccountInfoContext } from "../state/AccountInfoContext";
import { usePostRequest } from "./usePostRequest";

export enum ChangeEmailResponseError {
  EMailAlreadyUsed = "EMailAlreadyUsed",
  MatchesOldEMail = "MatchesOldEMail",
  InternalError = "InternalError",
}

export const useChangeEmailRequest = () => {
  const post = usePostRequest();
  const { info, setInfo } = useAccountInfoContext();

  return async (newEmail: string): Promise<ChangeEmailResponse> => {
    const request: ChangeEmailRequest = {
      email: newEmail,
    };

    const response = await post(Endpoints.changeEmail, request);

    if (!response || !response.ok) {
      return { error: ChangeEmailResponseError.InternalError, email: null };
    }

    const json = await response.json();
    const requestResponse = json as ChangeEmailResponse;

    if (requestResponse.error) {
      return requestResponse;
    }

    if (info) {
      setInfo({ ...info, email: requestResponse.email });
    }

    return requestResponse;
  };
};

interface ChangeEmailRequest {
  email: string;
}

type ChangeEmailResponse =
  | { email: string; error: null }
  | { email: null; error: ChangeEmailResponseError };
