import { useState, useEffect, MutableRefObject, useRef } from "react";

export function useWasVisibleOnce<T>(): [boolean, MutableRefObject<T | null>] {
  const [isVisible, setVisible] = useState(false);
  const observer = useRef(
    new IntersectionObserver(entries => {
      if (!isVisible && entries.some(it => it.isIntersecting)) {
        setVisible(true);
      }
    })
  );
  const domRef = useRef<T>();

  useEffect(() => {
    const ref = domRef.current;
    const currentObserver = observer.current;
    currentObserver.observe(ref as any);
    return () => {
      currentObserver.unobserve(ref as any);
    };
  }, []);

  return [isVisible, domRef as any];
}
