import { TwoColumnSection } from "./TwoColumnSection";

import section4TitleImage from "../../assets/economy_color.png";
import background3 from "../../assets/signup_bg.png";
import { TextList } from "../common/TextList";

export const EconomySection = () => {
  return (
    <TwoColumnSection
      hasShadow
      backgroundImageSrc={background3}
      scrollFrames={frames}
      titleImageSrc={section4TitleImage}
      titleImageAlt="Master New Skills"
      trackingContentType={"LandingPage_Section4Visible"}
    >
      <div className="text-light my-4 responsive-font-size">
        <TextList>
          <span>
            Buy/rent all kinds of <strong>unique properties</strong>.
          </span>

          <span>
            Create a <strong>Player shop</strong> and sell your wares!
          </span>

          <span>
            Run a <strong>Player restaurant</strong> and feed the town!
          </span>

          <span>
            True <strong>player economy</strong> where your effort matters!
          </span>

          <span>
            <strong>Trading system</strong> for on the go trading by hand.
          </span>
        </TextList>
      </div>
    </TwoColumnSection>
  );
};

const frames = [
  require("../../assets/frames_economy/ezgif-frame-001.jpg"),
  require("../../assets/frames_economy/ezgif-frame-002.jpg"),
  require("../../assets/frames_economy/ezgif-frame-003.jpg"),
  require("../../assets/frames_economy/ezgif-frame-004.jpg"),
  require("../../assets/frames_economy/ezgif-frame-005.jpg"),
  require("../../assets/frames_economy/ezgif-frame-006.jpg"),
  require("../../assets/frames_economy/ezgif-frame-007.jpg"),
  require("../../assets/frames_economy/ezgif-frame-008.jpg"),
  require("../../assets/frames_economy/ezgif-frame-009.jpg"),
  require("../../assets/frames_economy/ezgif-frame-010.jpg"),
  require("../../assets/frames_economy/ezgif-frame-011.jpg"),
  require("../../assets/frames_economy/ezgif-frame-012.jpg"),
  require("../../assets/frames_economy/ezgif-frame-013.jpg"),
  require("../../assets/frames_economy/ezgif-frame-014.jpg"),
  require("../../assets/frames_economy/ezgif-frame-015.jpg"),
  require("../../assets/frames_economy/ezgif-frame-016.jpg"),
  require("../../assets/frames_economy/ezgif-frame-017.jpg"),
  require("../../assets/frames_economy/ezgif-frame-018.jpg"),
  require("../../assets/frames_economy/ezgif-frame-019.jpg"),
  require("../../assets/frames_economy/ezgif-frame-020.jpg"),
  require("../../assets/frames_economy/ezgif-frame-021.jpg"),
  require("../../assets/frames_economy/ezgif-frame-022.jpg"),
  require("../../assets/frames_economy/ezgif-frame-023.jpg"),
  require("../../assets/frames_economy/ezgif-frame-024.jpg"),
  require("../../assets/frames_economy/ezgif-frame-025.jpg"),
  require("../../assets/frames_economy/ezgif-frame-026.jpg"),
  require("../../assets/frames_economy/ezgif-frame-027.jpg"),
  require("../../assets/frames_economy/ezgif-frame-028.jpg"),
  require("../../assets/frames_economy/ezgif-frame-029.jpg"),
  require("../../assets/frames_economy/ezgif-frame-030.jpg"),
  require("../../assets/frames_economy/ezgif-frame-031.jpg"),
  require("../../assets/frames_economy/ezgif-frame-032.jpg"),
  require("../../assets/frames_economy/ezgif-frame-033.jpg"),
  require("../../assets/frames_economy/ezgif-frame-034.jpg"),
  require("../../assets/frames_economy/ezgif-frame-035.jpg"),
  require("../../assets/frames_economy/ezgif-frame-036.jpg"),
  require("../../assets/frames_economy/ezgif-frame-037.jpg"),
  require("../../assets/frames_economy/ezgif-frame-038.jpg"),
  require("../../assets/frames_economy/ezgif-frame-039.jpg"),
  require("../../assets/frames_economy/ezgif-frame-040.jpg"),
  require("../../assets/frames_economy/ezgif-frame-041.jpg"),
  require("../../assets/frames_economy/ezgif-frame-042.jpg"),
];
