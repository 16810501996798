import { Button } from "./Button";
import { Modal, ModalProps } from "./Modal";
import { Paragraph } from "./Paraprah";

type Props = ModalProps & {
  title: string;
  message: string;
  disabled?: boolean;
  overlay?: "processing" | { error: string };
  onConfirm(): void;
  onCancel(): void;
};

export const ConfirmModal = ({
  title,
  message,
  dialogClass,
  overlay,
  onConfirm,
  onCancel,
  children,
  ...props
}: React.PropsWithChildren<Props>) => {
  return (
    <Modal title={title} className="max-w-[400px]" {...props}>
      <Paragraph>{message}</Paragraph>
      <div className="flex flex-row">
        <Button
          className="flex-grow mr-1"
          onClick={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          Yes
        </Button>
        <Button
          className="flex-grow ml-1"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          Cancel
        </Button>
      </div>
      {children}
    </Modal>
  );
};
