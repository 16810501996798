import * as React from "react";

export const SupportEmailLink = () => {
  return (
    <a
      className="text-blue-500 hover:underline"
      href="mailto:support@chronocraftgame.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      support@chronocraftgame.com
    </a>
  );
};
