import { Page } from "../Page";
import { BetaSignUpForm } from "./BetaSignUpForm";

export const PlayNowPage = () => {
  return (
    <div
      className="w-full flex overflow-hidden"
      style={{
        backgroundImage: "url(" + require("../../assets/signup_bg.png") + ")",
      }}
    >
      <Page hideHeader hideFooter>
        <div className="relative p-4 sm:p-8 md:p-16 flex flex-col items-center">
          <BetaSignUpForm />
        </div>
      </Page>
    </div>
  );
};
