import { useEffect } from "react";
import "./TexturedButton.css";

export const TexturedButton = ({
  width,
  height,
  onPress,
  bgSrc,
  bgHoverSrc,
  bgActiveSrc,
  bgDisabledSrc,
  className,
  disabled,
}: {
  width?: number | string;
  height?: number | string;
  onPress?: () => void;
  className?: string;
  bgSrc: string;
  bgHoverSrc: string;
  bgActiveSrc: string;
  bgDisabledSrc?: string;
  disabled?: boolean;
}) => {
  useEffect(() => {
    [bgDisabledSrc, bgActiveSrc, bgHoverSrc, bgSrc].forEach((src) => {
      if (src) {
        const img = new Image();
        img.src = src;
      }
    });
  }, [bgDisabledSrc, bgActiveSrc, bgHoverSrc, bgSrc]);

  const disableSource = disabled ? bgDisabledSrc : undefined;
  return (
    <div
      className={`${
        disabled ? "" : "cursor-pointer"
      } bg-cover textured-button-bg hover:textured-button-bg active:textured-button-bg ${
        className ?? ""
      }`}
      style={
        {
          "--textured-button-bg": `url(${disableSource ?? bgSrc})`,
          "--textured-button-bg-hover": `url(${disableSource ?? bgHoverSrc})`,
          "--textured-button-bg-active": `url(${disableSource ?? bgActiveSrc})`,
          width,
          height,
          disabled,
        } as any
      }
      onClick={!disabled ? onPress : undefined}
    />
  );
};
