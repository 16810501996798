import { DefaultRoutes } from "../../DefaultRoutes";
import {
  IconDiscord,
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconTwitter,
  IconYouTube,
} from "../common/SocialIcons";
import { TexturedButton } from "../common/TexturedButton";
import { TrackedLink } from "../utility/TrackedLink";

export const BetaSignUpSection = () => {
  return (
    <div
      className="relative py-[112px] flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: "url(" + require("../../assets/bottom_bg.png") + ")",
      }}
    >
      <TrackedLink
        to={DefaultRoutes.signUp}
        trackingName={"LandingPage_BetaSignUpBottomClicked"}
      >
        <TexturedButton
          className="max-w-[494px] max-h-[128px]"
          width={"80vw"}
          height={"20.72vw"}
          bgSrc={require("../../assets/betasignup.png")}
          bgHoverSrc={require("../../assets/betasignup_hover.png")}
          bgActiveSrc={require("../../assets/betasignup_press.png")}
        />
      </TrackedLink>
      <div className="absolute right-0 bottom-0 flex flex-row gap-2 mr-3 mb-2">
        <IconYouTube />
        <IconTikTok />
        <IconInstagram />
        <IconTwitter />
        <IconFacebook />
        <IconDiscord />
      </div>
    </div>
  );
};
