import { useCallback, useState } from "react";
import { SubscriptionStatus } from "../../state/SubscriptionStatus";
import { CancelSubscriptionModal } from "../SubscriptionModals/CancelSubscriptionModal/CancelSubscriptionModal";
import { CancelSubscriptionSuccessModal } from "../SubscriptionModals/CancelSubscriptionModal/CancelSubscriptionSuccessModal";

export const ChangeSubscriptionStatus = ({
  status,
}: {
  status: SubscriptionStatus;
}) => {
  const cancelCallbacks = useModals();
  return (
    <>
      <CancelModals {...cancelCallbacks} />
      <Link
        status={status}
        cancel={() => cancelCallbacks.setModalVisible(true)}
      />
    </>
  );
};

const Link = ({
  status,
  cancel,
}: {
  status: SubscriptionStatus;
  cancel: () => void;
}) => {
  if (status === "ACTIVE") {
    return (
      <span className="cursor-pointer text-blue-500" onClick={cancel}>
        Cancel Subscription
      </span>
    );
  } else if (status === "CANCELLED") {
    return null;
  } else if (status === "INACTIVE") {
    return null;
  } else {
    return null;
  }
};

const useModals = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);

  const onSuccess = useCallback(() => {
    setModalVisible(false);
    setSuccessModalVisible(true);
  }, [setModalVisible, setSuccessModalVisible]);

  return {
    isModalVisible,
    isSuccessModalVisible,
    onSuccess,
    setModalVisible,
    setSuccessModalVisible,
  };
};

type ModalCallbacks = ReturnType<typeof useModals>;

const CancelModals = ({
  isModalVisible,
  isSuccessModalVisible,
  onSuccess,
  setModalVisible,
  setSuccessModalVisible,
}: ModalCallbacks) => {
  return (
    <>
      {isModalVisible && (
        <CancelSubscriptionModal
          onExit={() => {
            setModalVisible(false);
          }}
          onSuccess={onSuccess}
        />
      )}
      {isSuccessModalVisible && (
        <CancelSubscriptionSuccessModal
          onExit={() => {
            setSuccessModalVisible(false);
          }}
        />
      )}
    </>
  );
};
