import * as React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { StyledTooltip } from "./StyledTooltip";
import { InputWrapper } from "./InputWrapper";
import "./FormInput.css";

export interface FormInputProps {
  placeholder: string;
  value: string;
  type?: "password" | "select";
  values?: { value: string; text: string }[];
  readonly?: boolean;
  disabled?: boolean;
  tooltip?: string;
  errorMessage?: string;
  fadeIn?: boolean;
  className?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isLeft?: boolean;
  isRight?: boolean;
  name?: string;

  onBlur?(): void;
  onFocus?(): void;
  onChange?(value: string): void;
  onSubmit?(): void;
}

export const useFocused = () => {
  const [isFocused, setIsFocused] = React.useState(false);

  return {
    isFocused,
    onBlur: () => {
      setIsFocused(false);
    },
    onFocus: () => {
      setIsFocused(true);
    },
  };
};

export const FormInput = ({
  placeholder,
  value,
  values,
  onBlur,
  onFocus,
  onChange,
  type,
  readonly,
  disabled,
  tooltip,
  errorMessage,
  onSubmit,
  fadeIn,
  className,
  isFirst,
  isLast,
  isLeft,
  isRight,
  name,
}: FormInputProps) => {
  const innerOnBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.nativeEvent.keyCode === 13 && onSubmit) {
      event.preventDefault();
      onSubmit();
    }
  };

  let classes = "";
  if (fadeIn) {
    classes += "form-input-fade-in ";
  }
  if (type === "select") {
    classes += "form-input-select ";
  }

  if (className) {
    classes += className;
  }
  const errorColor = "rgb(255, 50, 50)";
  return (
    <InputWrapper
      className={classes}
      error={!!errorMessage}
      style={{
        maxWidth: isLeft || isRight ? 117 : undefined,
      }}
      isFirst={isFirst}
      isLast={isLast}
      isLeft={isLeft}
      isRight={isRight}
    >
      <div className="flex flex-col flex-grow">
        <div className="flex flex-row flex-grow-0">
          {type === "select" ? (
            <select
              className="p-2 border-none appearance-none text-sm cursor-pointer bg-[#172F66] text-white disabled:text-gray-500 invalid:text-gray-500"
              value={value}
              placeholder={placeholder}
              onBlur={innerOnBlur}
              onFocus={onFocus}
              onChange={(el) => onChange && onChange(el.target.value)}
              disabled={disabled || (values ?? []).length < 2}
              style={{ flexGrow: 1 } as any}
              size={1}
              required
            >
              {values?.map(({ value, text }) => (
                <option
                  key={value}
                  value={value}
                  disabled={!value}
                  hidden={!value}
                  className="text-white"
                >
                  {text}
                </option>
              ))}
            </select>
          ) : (
            <input
              className="p-2 border-none overflow-hidden flex-grow text-sm bg-transparent placeholder:text-gray-500 text-white "
              name={name}
              value={value}
              placeholder={placeholder}
              onBlur={innerOnBlur}
              onFocus={onFocus}
              onChange={(el) => onChange && onChange(el.target.value)}
              type={type}
              readOnly={readonly}
              disabled={disabled}
              style={{ fontFamily: '"Roboto", sans-serif' }}
              onKeyPress={onKeyPress}
              size={1}
            />
          )}
          {!!errorMessage && (
            <StyledTooltip title={errorMessage} placement="top">
              <span
                className="px-2 py-1 mt-1"
                style={{ color: "#e00", fontWeight: "bold" }}
              >
                !
              </span>
            </StyledTooltip>
          )}
          {!!tooltip && (
            <StyledTooltip title={tooltip} placement="top">
              <InfoIcon style={{ alignSelf: "center" }} htmlColor="#777" />
            </StyledTooltip>
          )}
          {isLeft && (
            <div
              style={{
                borderLeft: `1px solid ${
                  errorMessage ? errorColor : "#c9c9ca"
                }`,
                marginBottom: errorMessage ? 0 : 8,
              }}
            />
          )}
        </div>
        {!isLast && (
          <hr
            className="border-input-top-left"
            style={{
              height: 1,
              margin: 0,
              borderColor: errorMessage ? errorColor : undefined,
              marginLeft: errorMessage ? 0 : 16,
              marginRight: errorMessage ? 0 : 16,
              marginTop: -1,
            }}
          />
        )}
      </div>
    </InputWrapper>
  );
};
