import * as React from "react";
import { DefaultRoutes } from "../DefaultRoutes";
import { TrackedLink } from "./utility/TrackedLink";

export const PageFooter = ({ hideLinks }: { hideLinks?: boolean }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginRight: 16,
        marginBottom: 8,
      }}
    >
      {!hideLinks && (
        <>
          <TrackedLink
            to={DefaultRoutes.conditions}
            trackingName={"Footer_ConditionsClicked"}
          >
            <span className="small-link text-dark">{"Terms & Conditions"}</span>
          </TrackedLink>
          <TrackedLink
            to={DefaultRoutes.privacyPolicy}
            trackingName={"Footer_PrivacyPolicyClicked"}
            style={{ marginLeft: 16 }}
          >
            <span className="small-link text-dark">Privacy Policy</span>
          </TrackedLink>
        </>
      )}
    </div>
  );
};
