import { Endpoints } from "../Endpoints";
import { usePostRequest } from "./usePostRequest";

export enum ResetPasswordResponseError {
  NOT_FOUND = "NOT_FOUND",
  INTERNAL = "INTERNAL",
}

export const useResetPasswordRequest = () => {
  const post = usePostRequest();

  return async (email: string): Promise<ResetPasswordResponse> => {
    const request: ResetPasswordRequest = {
      email,
    };

    const response = await post(Endpoints.resetPassword, request);

    if (!response || !response.ok) {
      return { error: ResetPasswordResponseError.INTERNAL };
    }

    const json = await response.json();
    const requestResponse = json as ResetPasswordResponse;

    return requestResponse;
  };
};

interface ResetPasswordRequest {
  email: string;
}

interface ResetPasswordResponse {
  error?: ResetPasswordResponseError;
}
