import { Route, Routes } from "react-router-dom";
import "./App.css";
import { DefaultRoutes } from "./DefaultRoutes";
import { AccountPage } from "./components/AccountPage/AccountPage";
import { ConditionsPage } from "./components/ConditionsPage/ConditionsPage";
import { LandingPage } from "./components/LandingPage/LandingPage";
import { PlayNowPage } from "./components/PlayNowPage/PlayNowPage";
import { PrivacyPolicyPage } from "./components/PrivacyPolicyPage/PrivacyPolicyPage";
import { ResetPasswordPage } from "./components/ResetPasswordPage/ResetPasswordPage";
import { ScrollToTop } from "./components/ScrollToTop";
import { useRequestAccountInfo } from "./requests/useRequestAccountInfo";
import { useLoadAndPersistAuthToken } from "./state/useLoadAndPersistAuthToken";
import { BlogPage } from "./components/BlogPage/BlogPage";

function App() {
  useLoadAndPersistAuthToken();
  useRequestAccountInfo();

  return (
    <>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path={DefaultRoutes.landingPage} element={<LandingPage />} />
          <Route path={DefaultRoutes.account} element={<AccountPage />} />
          <Route path={DefaultRoutes.signUp} element={<PlayNowPage />}>
            <Route path={DefaultRoutes.signUpSuccess} />
          </Route>
          <Route path={DefaultRoutes.conditions} element={<ConditionsPage />} />
          <Route
            path={DefaultRoutes.privacyPolicy}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={DefaultRoutes.resetPassword}
            element={<ResetPasswordPage />}
          />
          <Route
            path={DefaultRoutes.blog}
            element={<BlogPage />}
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
