import { useEffect } from "react";
import { Endpoints } from "../Endpoints";
import { AccountInfo } from "../state/AccountInfo";
import { useAccountInfoContext } from "../state/AccountInfoContext";
import { useAuthContext } from "../state/AuthContext";
import { usePostRequest } from "./usePostRequest";

export const useRequestAccountInfo = () => {
  const post = usePostRequest();
  const { setInfo } = useAccountInfoContext();
  const { state } = useAuthContext();
  const authenticated = state.type === "authenticated";

  useEffect(() => {
    const fetchAccountInfo = async () => {
      const response = await post(Endpoints.accountInfo);

      if (!response || !response.ok) {
        console.error("Error fetching account info: ", response?.status);
        return;
      }

      const json = await response.json();
      const info = json as AccountInfo;

      setInfo(info);
    };

    if (authenticated) {
      fetchAccountInfo();
    } else {
      setInfo(null);
    }
  }, [authenticated, post, setInfo]);
};
