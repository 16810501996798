export const IconYouTube = () => {
  return (
    <a
      href="https://www.youtube.com/@ChronoCraft"
      target="_blank"
      rel="noreferrer"
      className={`${sizeClassName} bg-contain`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        focusable="false"
        style={{
          pointerEvents: "none",
          display: "block",
        }}
      >
        <defs>
          <radialGradient
            cx="5.4%"
            cy="7.11%"
            r="107.93%"
            fx="5.4%"
            fy="7.11%"
            gradientTransform="matrix(.70653 0 0 1 .016 0)"
          >
            <stop offset="0%" stopColor="#FFF"></stop>
            <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
          </radialGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 1h21.77v22H1z"></path>
          <g fillRule="nonzero">
            <path
              fill="#F00"
              d="M22.54 7.6s-.2-1.5-.86-2.17c-.83-.87-1.75-.88-2.18-.93-3.04-.22-7.6-.2-7.6-.2s-4.56-.02-7.6.2c-.43.05-1.35.06-2.18.93-.65.67-.86 2.18-.86 2.18S1.04 9.4 1 11.18v1.66c.04 1.78.26 3.55.26 3.55s.2 1.5.86 2.18c.83.87 1.9.84 2.4.94 1.7.15 7.2.2 7.38.2 0 0 4.57 0 7.6-.22.43-.05 1.35-.06 2.18-.93.65-.67.86-2.18.86-2.18s.22-1.77.24-3.55v-1.66c-.02-1.78-.24-3.55-.24-3.55z"
            ></path>
            <path fill="#FAFAFA" d="M9.68 8.9v6.18l5.84-3.1"></path>
            <path
              fill="#000"
              fillOpacity=".12"
              d="M9.68 8.88l5.13 3.48.73-.38"
            ></path>
            <path
              fill="#FFF"
              fillOpacity=".2"
              d="M22.54 7.6s-.2-1.5-.86-2.17c-.83-.87-1.75-.88-2.18-.93-3.04-.22-7.6-.2-7.6-.2s-4.56-.02-7.6.2c-.43.05-1.35.06-2.18.93-.65.67-.86 2.18-.86 2.18S1.04 9.4 1 11.18v.1c.04-1.76.26-3.54.26-3.54s.2-1.5.86-2.17c.83-.88 1.75-.88 2.18-.93 3.04-.22 7.6-.2 7.6-.2s4.56-.02 7.6.2c.43.05 1.35.05 2.18.93.65.66.86 2.17.86 2.17s.22 1.78.23 3.55v-.1c0-1.8-.23-3.56-.23-3.56z"
            ></path>
            <path
              fill="#3E2723"
              fillOpacity=".2"
              d="M22.54 16.4s-.2 1.5-.86 2.17c-.83.87-1.75.88-2.18.93-3.04.22-7.6.2-7.6.2s-4.56.02-7.6-.2c-.43-.05-1.35-.06-2.18-.93-.65-.67-.86-2.18-.86-2.18s-.22-1.8-.26-3.57v-.1c.04 1.76.26 3.54.26 3.54s.2 1.5.86 2.17c.83.88 1.75.88 2.18.93 3.04.22 7.6.2 7.6.2s4.56.02 7.6-.2c.43-.05 1.35-.05 2.18-.93.65-.66.86-2.17.86-2.17s.22-1.78.23-3.55v.1c0 1.8-.23 3.56-.23 3.56z"
            ></path>
            <path
              fill="#FFF"
              fillOpacity=".2"
              d="M9.68 15.08v.1l5.84-3.08v-.12"
            ></path>
            <path
              fill="#3E2723"
              fillOpacity=".2"
              d="M9.68 8.9v-.13l5.84 3.1v.1"
            ></path>
            <path
              fill="url(#youtube_round__a)"
              fillOpacity=".1"
              d="M21.54 3.4s-.2-1.5-.86-2.18C19.85.35 18.93.35 18.5.3 15.46.07 10.9.1 10.9.1S6.34.07 3.3.3c-.43.05-1.35.05-2.18.92C.47 1.9.26 3.4.26 3.4S.04 5.17 0 6.95V8.6c.04 1.8.26 3.56.26 3.56s.2 1.52.86 2.18c.83.87 1.9.85 2.4.94 1.7.16 7.2.2 7.38.2 0 0 4.57 0 7.6-.2.43-.06 1.35-.07 2.18-.94.65-.66.86-2.18.86-2.18s.22-1.77.24-3.55V6.97c-.02-1.78-.24-3.55-.24-3.55z"
              transform="translate(1 4.208)"
            ></path>
          </g>
        </g>
      </svg>
    </a>
  );
};

export const IconTwitter = () => {
  return (
    <a
      href="https://twitter.com/Chrono_Craft"
      target="_blank"
      rel="noreferrer"
      className={`${sizeClassName} bg-contain overflow-hidden flex items-center justify-center`}
    >
      <svg
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="417 305 834 610"
        // enableBackground={'new 0 0 834 610'}
        xmlSpace="preserve"
        className={`${sizeClassNameInner} bg-black rounded-2xl`}
      >
        <style type="text/css">
          {`.st0{stroke:#FFFFFF;strokeMiterlimit:10;}
        .st1{fill:#FFFFFF;}`}
        </style>

        <g transform="translate(52.390088,-25.058597)">
          <path
            className="st1"
            d="M491.03,374.75L721.1,682.38L489.58,932.49h52.11l202.7-218.98l163.77,218.98h177.32L842.46,607.56l215.5-232.81h-52.11L819.18,576.42L668.35,374.75H491.03z M567.66,413.14h81.46l359.72,480.97h-81.46L567.66,413.14z"
          />
        </g>
      </svg>
    </a>
  );
};

export const IconFacebook = () => {
  return (
    <a
      href="https://www.facebook.com/ChronoCraftOfficial/"
      target="_blank"
      rel="noreferrer"
      className={`${sizeClassName} bg-contain flex items-center justify-center`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 40 40"
        className={sizeClassNameInner}
      >
        <path
          fill="#0062e0"
          d="M16.7 39.8C7.2 38.1 0 29.9 0 20 0 9 9 0 20 0s20 9 20 20c0 9.9-7.2 18.1-16.7 19.8l-1.1-.9h-4.4l-1.1.9z"
        />
        <path
          fill="#fff"
          d="m27.8 25.6.9-5.6h-5.3v-3.9c0-1.6.6-2.8 3-2.8H29V8.2c-1.4-.2-3-.4-4.4-.4-4.6 0-7.8 2.8-7.8 7.8V20h-5v5.6h5v14.1c1.1.2 2.2.3 3.3.3 1.1 0 2.2-.1 3.3-.3V25.6h4.4z"
        />
      </svg>
    </a>
  );
};

export const IconInstagram = () => {
  return (
    <a
      href="https://www.instagram.com/chrono_craft"
      target="_blank"
      rel="noreferrer"
      className={`${sizeClassName} bg-contain`}
      style={{
        backgroundImage: "url()",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={sizeClassName}
        viewBox="-5 -5 64.14631 64.93805"
      >
        <defs>
          <linearGradient
            id="a"
            y2="1.7526"
            x2="1.7537"
            y1="-5.8041"
            x1="-5.8093"
          >
            <stop offset="0%" stopColor="#FFD521" />
            <stop offset="5%" stopColor="#FFD521" />
            <stop offset="50.1119%" stopColor="#F50000" />
            <stop offset="95%" stopColor="#B900B4" />
            <stop offset="95.0079%" stopColor="#B900B4" />
            <stop offset="100%" stopColor="#B900B4" />
          </linearGradient>
          <linearGradient id="b" y2=".9175" x2=".9175" y1=".0091" x1=".009">
            <stop offset="0%" stopColor="#FFD521" />
            <stop offset="5%" stopColor="#FFD521" />
            <stop offset="50.1119%" stopColor="#F50000" />
            <stop offset="95%" stopColor="#B900B4" />
            <stop offset="95.0079%" stopColor="#B900B4" />
            <stop offset="100%" stopColor="#B900B4" />
          </linearGradient>
          <linearGradient id="c" y2="1.3128" x2="1.3126" y1="-.456" x1="-.4558">
            <stop offset="0%" stopColor="#FFD521" />
            <stop offset="5%" stopColor="#FFD521" />
            <stop offset="50.1119%" stopColor="#F50000" />
            <stop offset="95%" stopColor="#B900B4" />
            <stop offset="95.0079%" stopColor="#B900B4" />
            <stop offset="100%" stopColor="#B900B4" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            gradientTransform="scale(1.00041 .99959)"
            y2="11.412"
            x2="11.3667"
            y1="-37.5455"
            x1="-37.6312"
            id="d"
            xlinkHref="#a"
          />
          <linearGradient
            gradientUnits="userSpaceOnUse"
            y2="49.554"
            x2="49.5047"
            y1=".536"
            x1=".4867"
            id="e"
            xlinkHref="#b"
          />
          <linearGradient
            gradientUnits="userSpaceOnUse"
            gradientTransform="scale(.99988 1.00012)"
            y2="36.4816"
            x2="36.4315"
            y1="-12.5305"
            x1="-12.5688"
            id="f"
            xlinkHref="#c"
          />
        </defs>
        <g fill="none">
          <path
            d="M6.4867 3.292c0 1.7933-1.4534 3.2413-3.24 3.2413C1.46 6.5333.0053 5.0853.0053 3.292.0053 1.5053 1.46.0573 3.2467.0573c1.7866 0 3.24 1.448 3.24 3.2347"
            fill="url(#d)"
            transform="matrix(1 0 0 -1 38.1333 15.8707)"
          />
          <path
            d="M48.9373 16.124c-.12-2.6307-.56-4.06-.9253-5.0093-.4907-1.2587-1.076-2.1587-2.0253-3.1027-.9387-.944-1.8387-1.528-3.0974-2.0133-.9493-.3707-2.384-.812-5.0146-.9374-2.844-.1253-3.6867-.152-10.8987-.152-7.2053 0-8.0547.0267-10.8987.152-2.6306.1254-4.0586.5667-5.008.9374C9.804 6.484 8.9107 7.068 7.9667 8.012c-.9507.944-1.536 1.844-2.02 3.1027-.3654.9493-.812 2.3786-.9254 5.0093-.1386 2.844-.164 3.7-.164 10.8973 0 7.212.0254 8.0614.164 10.9054.1134 2.6306.56 4.0586.9254 5.016.484 1.2573 1.0693 2.152 2.02 3.096.944.9426 1.8373 1.528 3.1026 2.0186.9494.372 2.3774.8067 5.008.932 2.844.1254 3.6934.1574 10.8987.1574 7.212 0 8.0547-.032 10.8987-.1574 2.6306-.1253 4.0653-.56 5.0146-.932 1.2587-.4906 2.1587-1.076 3.0974-2.0186.9493-.944 1.5346-1.8387 2.0253-3.096.3653-.9574.8053-2.3854.9253-5.016.132-2.844.164-3.6934.164-10.9054 0-7.1973-.032-8.0533-.164-10.8973zm4.8574 22.024c-.132 2.8747-.5854 4.8387-1.2587 6.5493-.6853 1.7747-1.604 3.2787-3.108 4.7827-1.4973 1.4973-3.0013 2.416-4.776 3.1093-1.7173.6667-3.6747 1.1254-6.5507 1.2507-2.876.1387-3.7946.164-11.1253.164-7.324 0-8.2493-.0253-11.1253-.164-2.8694-.1253-4.8254-.584-6.5507-1.2507-1.768-.6933-3.272-1.612-4.7693-3.1093-1.504-1.504-2.4227-3.008-3.1147-4.7827C.7493 42.9867.296 41.0227.1573 38.148.032 35.272 0 34.352 0 27.0213c0-7.324.032-8.2426.1573-11.1186.1387-2.8694.592-4.832 1.2587-6.5507.692-1.768 1.6107-3.2787 3.1147-4.776C6.028 3.0787 7.532 2.1533 9.3 1.4613c1.7253-.6666 3.6813-1.12 6.5507-1.252 2.876-.132 3.8013-.164 11.1253-.164 7.3307 0 8.2493.032 11.1253.164 2.876.132 4.8334.5854 6.5507 1.252 1.7747.692 3.2787 1.6174 4.776 3.1147 1.504 1.4973 2.4227 3.008 3.108 4.776.6733 1.7187 1.1267 3.6813 1.2587 6.5507.132 2.876.164 3.7946.164 11.1186 0 7.3307-.032 8.2507-.164 11.1267z"
            fill="url(#e)"
            transform="matrix(1 0 0 -1 0 54.004)"
          />
          <path
            d="M13.9093 4.9693c-4.964 0-8.992 4.0214-8.992 8.9854 0 4.972 4.028 8.9986 8.992 8.9986 4.9654 0 8.9987-4.0266 8.9987-8.9986 0-4.964-4.0333-8.9854-8.9987-8.9854zm0 22.848C6.2573 27.8173.06 21.6067.06 13.9547.06 6.3093 6.2573.1053 13.9093.1053c7.652 0 13.856 6.204 13.856 13.8494 0 7.652-6.204 13.8626-13.856 13.8626z"
            fill="url(#f)"
            transform="matrix(1 0 0 -1 13.0667 40.9373)"
          />
        </g>
      </svg>
    </a>
  );
};

export const IconDiscord = () => {
  return (
    <a href="http://discord.gg/cqDshSn" target="_blank" rel="noreferrer">
      <div
        className={`${sizeClassName} bg-contain`}
        style={{
          backgroundImage:
            "url(" + require("../../assets/discord-2-128.png") + ")",
        }}
      />
    </a>
  );
};

export const IconTikTok = () => {
  return (
    <a
      href="https://www.tiktok.com/@chronocraft"
      target="_blank"
      rel="noreferrer"
      className={`${sizeClassName} bg-contain flex items-center justify-center`}
    >
      <svg
        viewBox="0 0 291.72499821636245 291.1"
        xmlns="http://www.w3.org/2000/svg"
        className={sizeClassNameInner}
      >
        <path d="M219.51 291.1H71.58C32.05 291.1 0 259.05 0 219.51V71.58C0 32.05 32.05 0 71.58 0h147.93c39.53 0 71.58 32.05 71.58 71.58v147.93c.01 39.54-32.04 71.59-71.58 71.59z" />
        <g fill="#25f4ee">
          <path d="M120.96 123.89v-8.8a64.83 64.83 0 0 0-9.23-.79c-29.93-.06-56.42 19.33-65.41 47.87s1.62 59.62 26.18 76.71c-25.77-27.58-24.3-70.83 3.28-96.6a68.425 68.425 0 0 1 45.18-18.39z" />
          <path d="M122.62 223.53c16.73-.02 30.48-13.2 31.22-29.92V44.44h27.25a50.7 50.7 0 0 1-.79-9.44h-37.27v149.02c-.62 16.8-14.41 30.11-31.22 30.14-5.02-.04-9.97-1.28-14.42-3.6a31.276 31.276 0 0 0 25.23 12.97zM231.98 95.05v-8.29c-10.03 0-19.84-2.96-28.19-8.51a51.63 51.63 0 0 0 28.19 16.8z" />
        </g>
        <path
          d="M203.8 78.26a51.301 51.301 0 0 1-12.76-33.89h-9.95a51.564 51.564 0 0 0 22.71 33.89zM111.73 151.58c-17.28.09-31.22 14.17-31.13 31.45a31.293 31.293 0 0 0 16.71 27.53c-10.11-13.96-6.99-33.48 6.97-43.6a31.191 31.191 0 0 1 18.34-5.93c3.13.04 6.24.53 9.23 1.45v-37.93c-3.05-.46-6.14-.7-9.23-.72h-1.66v28.84c-3.01-.82-6.12-1.18-9.23-1.09z"
          fill="#fe2c55"
        />
        <path
          d="M231.98 95.05v28.84a88.442 88.442 0 0 1-51.69-16.8v75.77c-.08 37.81-30.75 68.42-68.56 68.42a67.816 67.816 0 0 1-39.22-12.4c25.73 27.67 69.02 29.25 96.7 3.52a68.397 68.397 0 0 0 21.83-50.09v-75.56a88.646 88.646 0 0 0 51.76 16.58V96.21c-3.64-.02-7.26-.4-10.82-1.16z"
          fill="#fe2c55"
        />
        <path
          d="M180.29 182.87V107.1a88.505 88.505 0 0 0 51.76 16.58V94.84a51.73 51.73 0 0 1-28.26-16.58 51.634 51.634 0 0 1-22.71-33.89h-27.25v149.24c-.71 17.27-15.27 30.69-32.54 29.99a31.278 31.278 0 0 1-24.06-12.9c-15.29-8.05-21.16-26.97-13.11-42.26a31.274 31.274 0 0 1 27.53-16.71c3.13.03 6.24.51 9.23 1.44V123.9c-37.74.64-67.82 32.19-67.18 69.93a68.353 68.353 0 0 0 18.73 45.86 67.834 67.834 0 0 0 39.29 11.61c37.82-.01 68.49-30.62 68.57-68.43z"
          fill="#fff"
        />
      </svg>
    </a>
  );
};

const sizeClassName = "w-[40px] h-[40px] sm:w-[64px] sm:h-[64px]"
const sizeClassNameInner = "w-[31.8px] h-[31.8px] sm:w-[53px] sm:h-[53px]"