import { Page } from "../Page";
import { Paragraph } from "../common/Paraprah";
import { Title } from "../common/Title";

import image1 from "../../assets/blog/image1.png";
import image2 from "../../assets/blog/image2.png";
import image3 from "../../assets/blog/image3.png";
import image4 from "../../assets/blog/image4.png";
import image5 from "../../assets/blog/image5.png";
import image6 from "../../assets/blog/image6.png";
import image7 from "../../assets/blog/image7.png";
import image8 from "../../assets/blog/image8.png";

export const BlogPage = () => {
  return (
    <Page>
      <div className="relative p-4 sm:p-8 md:p-16 flex flex-col items-center text-center">
        <div className="max-w-[1000px]">
          <img src={image1} alt="" />
          <Title>
            {"New Beginnings"}
            <span className="text-lg font-normal italic">
              {" "}
              (April 1st 2024 Blog Post)
            </span>
          </Title>
          <Paragraph>
            Hello fellow time travelers! This is the beginning of our Blog post
            series so the community can keep up with us as we approach Beta
            Launch. The ChronoCraft team has been hard at work on a few features
            since Christmas. Features like combat physics, New weapons, new
            monsters, a new skill, a new sound modifier feature and the quest
            making tool!
          </Paragraph>
          <Title className="mt-16">New Monsters</Title>
          <Paragraph>
            There are 4 new monster species:
            <br />
            <strong>ManEater</strong> (a man eating plant)
            <br />
            <strong>Piggle</strong> (A cute but feisty forager)
            <br />
            <strong>Gazebra</strong> (A graceful prey of the forest)
            <br />
            <strong>Burster</strong> (A bug you don't want to go "Splat")
          </Paragraph>
          <Paragraph>
            The ManEater is aggressive and has a dynamic range with sharp chomp
            attacks and poisonous spit that can travel far. In groups they can
            be as hard as a boss! So be Careful! It was a blast to playtest so
            we’re excited for players to experience the same fun we had! (90%
            complete)
          </Paragraph>
          <img src={image2} alt="" className="my-8" loading="lazy" />
          <Paragraph>
            Piggles make a great source of food in a pinch, but they won't go
            down without a fight. They are as short as their temper and aren't
            afraid of anything within their territory. (70% complete)
          </Paragraph>
          <img src={image3} alt="" className="my-8" loading="lazy"/>
          <Paragraph>
            Gazebras add life to the forest and meadows. They are currently the
            hardest monster to catch as their speed, evasion and gracefulness
            escapes all players except those who are experts at range. (90%
            complete)
          </Paragraph>
          <img src={image4} alt="" className="my-8" loading="lazy"/>
          <Paragraph>
            Bursters are the latest addition. They are the 1st mob to be able to
            latch on to players which means expect more mobs like this! They
            love dark places and can easily be found by the green luminescent
            glow they radiate occasionally like a firefly. After they die they
            can still cause problems with the acid puddle they leave behind.
            Let's just say they are bursting to meet new adventurers! (50%
            Complete)
          </Paragraph>
          <img src={image6} alt="" className="my-8" loading="lazy"/>
          <Title className="mt-16">New Weapon</Title>
          <Paragraph>
            The Spear is a farther reaching weapon than the sword that requires
            more aim and distance control. We expect it to be a weapon with a
            higher skill ceiling, but maybe players will surprise us. As with
            every weapon we will playtest this with the community volunteers to
            keep gameplay competitive and balanced. Balance is important to us
            to keep competitive integrity for PVP and PVP events like
            Tournaments that will have prizes. (80% Complete)
          </Paragraph>
          <img src={image5} alt="" className="my-8" loading="lazy"/>
          <Title className="mt-16">Combat Physics</Title>
          <Paragraph>
            In order to create extended air combos while balancing PvP we needed
            more fine controls over how knockback physics are calculated. We
            devised a special tool that lets us visualize predicted flight path
            and real flight path to get a better idea of how changing gravity,
            friction and speed affect knockback. This extra insight will help
            make new weapons more unique with special combos not possible
            before.
          </Paragraph>
          <img src={image8} alt="" className="my-8" loading="lazy"/>
          <Title className="mt-16">New Skill: Foraging</Title>
          <Paragraph>
            We removed the woodcutting skill and replaced it with the{" "}
            <strong>Foraging skill</strong>. Just like woodcutting it will
            affect players ability to chop specific wood types but, we’re taking
            it a step beyond. So far we have created a scan feature that will be
            used to reveal hidden plants, clues and treasures for the player to
            find. As you level up your Foraging skill, you are able to find new
            types of lootables adding a new joy to re-exploring existing areas
            for new treasures. (20% Complete)
          </Paragraph>
          <video autoPlay loop className="my-8">
            <source src="https://dtlod8orn734n.cloudfront.net/video/scan_2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Title className="mt-16">Sound Modifier Feature</Title>
          <Paragraph>
            We created another tool that gives an extra element to the
            environment that makes Chronocraft more lifelike through new sounds
            that could not be done before. This creates an immensely immersive
            experience, whether you’re hearing muffled sounds underwater or the
            clanging echo of mugs at the tavern. We want the world to tickle
            players' ears as they explore so that you feel as if you're there.
            In this video, you can hear the sounds take on a ghostly effect
            after eating a ghost steak:
          </Paragraph>
          <video controls className="my-8" width={'100%'}>
            <source src="https://dtlod8orn734n.cloudfront.net/video/Ghost_steak_v3_with_example_LPFilter_effect.webm" />
            Your browser does not support the video tag.
          </video>
          <Title className="mt-16">Quest Making</Title>
          <Paragraph>
            We are very excited about this feature. It will allow us and
            eventually players too to make quests in game. Our goal is to make
            it so there are multi-branching quests. Some will be serious,
            mysterious, and dark. Others will be wacky, light hearted and cute.
            Quests will even include parkour chases, detective quests,
            repeatable minigames and more to utilize different skills the player
            must use. The sky's the limit. Here is an image of the early stages
            of the Quest making tool: (5% Complete)
          </Paragraph>
          <img src={image7} alt="" className="my-8" loading="lazy"/>
          <Title className="mt-16">The Beginning of ChronoCraft (Lore)</Title>
          <Paragraph>
            The year is 2550. After a freak accident aboard the GSS (Galactic
            Space Station), you find yourself in a docked escape pod. You look
            at the window and realize that Earth’s being pulled into a wormhole.
            You hesitate to decide which fate is worse, but your choice is
            decided for you when the claws of a greater alien beast starts
            slowly tearing through your sealed door. You hit the eject button
            and jettison towards Earth’s unknown fate…
          </Paragraph>
          <Title className="mt-16">The Future of ChronoCraft</Title>
          <Paragraph>
            We are all extremely excited to get ChronoCraft to Beta Launch,
            however current timeline predictions are for Early 2025. We are in
            the process of trying to secure funding in order to make this
            happen. Eventually, we would like to take our dev tools and give
            them to the community to make their own worlds, adventures and games
            all within the ChronoCraft ecosystem (But that's a discussion for
            another time!). Follow us on our social media to stay in the loop!
          </Paragraph>
          <Paragraph>See you in the Chrono-verse!</Paragraph>
        </div>
      </div>
    </Page>
  );
};
