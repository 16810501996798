import { TexturedButton } from "../common/TexturedButton";

export const SignUpButton = (props: { className?: string, disabled?: boolean, onPress: () => void }) => {
  return (
    <TexturedButton
      className={props.className}
      width={720 / 3}
      height={256 / 3}
      bgSrc={require("../../assets/signup.png")}
      bgHoverSrc={require("../../assets/signup_hover.png")}
      bgActiveSrc={require("../../assets/signup_press.png")}
      bgDisabledSrc={require("../../assets/signup_innactive.png")}
      disabled={props.disabled}
      onPress={props.onPress}
    />
  );
};
