export const Heading = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h3 className={`font-bold text-lg my-5 ${props.className ?? ""}`}>
      {props.children}
    </h3>
  );
};
