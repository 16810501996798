import { Button } from "./Button";
import { Heading } from "./Heading";
import { Paragraph } from "./Paraprah";

export const InProgressOverlay = ({
  type,
}: {
  type: "modal" | "fullscreen";
}) => {
  return (
    <Overlay type={type}>
      <Heading className="text-light">Processing...</Heading>
    </Overlay>
  );
};

export const ErrorOverlay = ({
  type,
  error,
  onClose,
}: {
  type: "modal" | "fullscreen";
  error: string;
  onClose: () => void;
}) => {
  return (
    <Overlay type={type}>
      <Paragraph className="text-error">{error}</Paragraph>
      <Button
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
        className="min-w-[100px]"
      >
        Ok
      </Button>
    </Overlay>
  );
};

const Overlay = ({
  children,
  type,
}: React.PropsWithChildren<{ type: "modal" | "fullscreen" }>) => {
  return (
    <div
      className={`absolute ${
        type === "fullscreen" ? "inset-0" : "inset-[-1px]"
      } bg-black/80 rounded-md flex flex-col justify-center items-center p-4`}
    >
      {children}
    </div>
  );
};
