import { LoginForm } from "./LoginForm";

import { useAuthContext } from "../../state/AuthContext";
import { Page } from "../Page";
import { ManageAccount } from "./ManageAccount";

export const AccountPage = () => {
  const { state } = useAuthContext();

  return (
    <Page>
      {state.type === "loading" ? null : state.type === "unauthenticated" ? (
        <div
          className="p-8 md:p-16 flex flex-col h-full items-center"
          style={{
            backgroundImage: `url(${require("../../assets/signup_bg.png")})`,
          }}
        >
          <LoginForm />
        </div>
      ) : (
        <div className="p-8 md:p-16">
          <ManageAccount />
        </div>
      )}
    </Page>
  );
};
