import { useCallback } from "react";
import { Endpoints } from "../Endpoints";
import { usePostRequest } from "./usePostRequest";

export const useValidateTokenRequest = () => {
  const post = usePostRequest();

  return useCallback(
    async (token: string): Promise<ValidateTokenResponse> => {
      const request: ValidateTokenRequest = {
        token,
      };

      const response = await post(Endpoints.validateToken, request);

      if (!response || !response.ok) {
        return { valid: false };
      }

      const result = (await response.json()) as ValidateTokenResponse;
      return result;
    },
    [post]
  );
};

interface ValidateTokenRequest {
  token: string;
}

interface ValidateTokenResponse {
  valid: boolean;
}
