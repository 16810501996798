import section2TitleImage from "../../assets/community_color.png";
import background2 from "../../assets/signup_bg.png";
import { TextList } from "../common/TextList";
import { TwoColumnSection } from "./TwoColumnSection";

export const CommunitySection = () => {
  return (
    <TwoColumnSection
      hasShadow
      backgroundImageSrc={background2}
      scrollFrames={frames}
      titleImageSrc={section2TitleImage}
      titleImageAlt="Play With Friends"
      trackingContentType={"LandingPage_Section2Visible"}
    >
      <div className="text-light my-4 responsive-font-size">
        <TextList>
          <span>Community & Holiday events.</span>

          <span>
            Make your own <strong>world & quests</strong> with friends.
          </span>

          <span>
            Bring your friends to take on <strong>Raid Bosses</strong>.
          </span>

          <span>
            <strong>Join a Guild</strong> and achieve great feats together.
          </span>

          <span>
            <strong>Brave dungeons</strong> with a party of friends!
          </span>
        </TextList>
      </div>
    </TwoColumnSection>
  );
};

const frames = [
  require("../../assets/frames_community/ezgif-frame-001.jpg"),
  require("../../assets/frames_community/ezgif-frame-002.jpg"),
  require("../../assets/frames_community/ezgif-frame-003.jpg"),
  require("../../assets/frames_community/ezgif-frame-004.jpg"),
  require("../../assets/frames_community/ezgif-frame-005.jpg"),
  require("../../assets/frames_community/ezgif-frame-006.jpg"),
  require("../../assets/frames_community/ezgif-frame-007.jpg"),
  require("../../assets/frames_community/ezgif-frame-008.jpg"),
  require("../../assets/frames_community/ezgif-frame-009.jpg"),
  require("../../assets/frames_community/ezgif-frame-010.jpg"),
  require("../../assets/frames_community/ezgif-frame-011.jpg"),
  require("../../assets/frames_community/ezgif-frame-012.jpg"),
  require("../../assets/frames_community/ezgif-frame-013.jpg"),
  require("../../assets/frames_community/ezgif-frame-014.jpg"),
  require("../../assets/frames_community/ezgif-frame-015.jpg"),
  require("../../assets/frames_community/ezgif-frame-016.jpg"),
  require("../../assets/frames_community/ezgif-frame-017.jpg"),
  require("../../assets/frames_community/ezgif-frame-018.jpg"),
  require("../../assets/frames_community/ezgif-frame-019.jpg"),
  require("../../assets/frames_community/ezgif-frame-020.jpg"),
  require("../../assets/frames_community/ezgif-frame-021.jpg"),
  require("../../assets/frames_community/ezgif-frame-022.jpg"),
  require("../../assets/frames_community/ezgif-frame-023.jpg"),
  require("../../assets/frames_community/ezgif-frame-024.jpg"),
  require("../../assets/frames_community/ezgif-frame-025.jpg"),
  require("../../assets/frames_community/ezgif-frame-026.jpg"),
  require("../../assets/frames_community/ezgif-frame-027.jpg"),
  require("../../assets/frames_community/ezgif-frame-028.jpg"),
  require("../../assets/frames_community/ezgif-frame-029.jpg"),
  require("../../assets/frames_community/ezgif-frame-030.jpg"),
  require("../../assets/frames_community/ezgif-frame-031.jpg"),
  require("../../assets/frames_community/ezgif-frame-032.jpg"),
  require("../../assets/frames_community/ezgif-frame-033.jpg"),
  require("../../assets/frames_community/ezgif-frame-034.jpg"),
  require("../../assets/frames_community/ezgif-frame-035.jpg"),
  require("../../assets/frames_community/ezgif-frame-036.jpg"),
  require("../../assets/frames_community/ezgif-frame-037.jpg"),
  require("../../assets/frames_community/ezgif-frame-038.jpg"),
  require("../../assets/frames_community/ezgif-frame-039.jpg"),
  require("../../assets/frames_community/ezgif-frame-040.jpg"),
  require("../../assets/frames_community/ezgif-frame-041.jpg"),
  require("../../assets/frames_community/ezgif-frame-042.jpg"),
];
