import React from "react";

export const TextList = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <ul className={`${props.className ?? ""}`}>
      {React.Children.map(props.children, (child, index) => (
        <li key={index} className="my-4 flex flex-row">
          {"\u2022"}
          <span className="pl-2" style={{ lineHeight: 1.2 }}>
            {child}
          </span>
        </li>
      ))}
    </ul>
  );
};
