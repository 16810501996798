import section3TitleImage from "../../assets/world_color.png";
import { TextList } from "../common/TextList";
import { TwoColumnSection } from "./TwoColumnSection";

export const WorldSection = () => {
  return (
    <TwoColumnSection
      reverseColumns
      scrollFrames={frames}
      titleImageSrc={section3TitleImage}
      titleImageAlt="Explore the World"
      trackingContentType={"LandingPage_Section3Visible"}
    >
      <div className="text-dark md:text-light my-4 responsive-font-size">
        <TextList>
          <span>
            Fight strange <strong>Monsters</strong>.
          </span>

          <span>
            Discover and <strong>tame pets</strong>.
          </span>

          <span>
            Explore the <strong>world</strong> and <strong>timeline</strong>{" "}
            from Ancient Egyptian Cities to futuristic Citadels.
          </span>

          <span>
            <strong>Uncover secrets</strong> & supplies hidden in the world.
          </span>

          <span>
            Thousands of combinations to <strong>customize</strong> your avatar.
          </span>

          <span>
            <strong>Decorate your home</strong> with furniture from your travels
          </span>
        </TextList>
      </div>
    </TwoColumnSection>
  );
};

const frames = [
  require("../../assets/frames_world/ezgif-frame-001.jpg"),
  require("../../assets/frames_world/ezgif-frame-002.jpg"),
  require("../../assets/frames_world/ezgif-frame-003.jpg"),
  require("../../assets/frames_world/ezgif-frame-004.jpg"),
  require("../../assets/frames_world/ezgif-frame-005.jpg"),
  require("../../assets/frames_world/ezgif-frame-006.jpg"),
  require("../../assets/frames_world/ezgif-frame-007.jpg"),
  require("../../assets/frames_world/ezgif-frame-008.jpg"),
  require("../../assets/frames_world/ezgif-frame-009.jpg"),
  require("../../assets/frames_world/ezgif-frame-010.jpg"),
  require("../../assets/frames_world/ezgif-frame-011.jpg"),
  require("../../assets/frames_world/ezgif-frame-012.jpg"),
  require("../../assets/frames_world/ezgif-frame-013.jpg"),
  require("../../assets/frames_world/ezgif-frame-014.jpg"),
  require("../../assets/frames_world/ezgif-frame-015.jpg"),
  require("../../assets/frames_world/ezgif-frame-016.jpg"),
  require("../../assets/frames_world/ezgif-frame-017.jpg"),
  require("../../assets/frames_world/ezgif-frame-018.jpg"),
  require("../../assets/frames_world/ezgif-frame-019.jpg"),
  require("../../assets/frames_world/ezgif-frame-020.jpg"),
  require("../../assets/frames_world/ezgif-frame-021.jpg"),
  require("../../assets/frames_world/ezgif-frame-022.jpg"),
  require("../../assets/frames_world/ezgif-frame-023.jpg"),
  require("../../assets/frames_world/ezgif-frame-024.jpg"),
  require("../../assets/frames_world/ezgif-frame-025.jpg"),
  require("../../assets/frames_world/ezgif-frame-026.jpg"),
  require("../../assets/frames_world/ezgif-frame-027.jpg"),
  require("../../assets/frames_world/ezgif-frame-028.jpg"),
  require("../../assets/frames_world/ezgif-frame-029.jpg"),
  require("../../assets/frames_world/ezgif-frame-030.jpg"),
  require("../../assets/frames_world/ezgif-frame-031.jpg"),
  require("../../assets/frames_world/ezgif-frame-032.jpg"),
  require("../../assets/frames_world/ezgif-frame-033.jpg"),
  require("../../assets/frames_world/ezgif-frame-034.jpg"),
  require("../../assets/frames_world/ezgif-frame-035.jpg"),
  require("../../assets/frames_world/ezgif-frame-036.jpg"),
  require("../../assets/frames_world/ezgif-frame-037.jpg"),
  require("../../assets/frames_world/ezgif-frame-038.jpg"),
  require("../../assets/frames_world/ezgif-frame-039.jpg"),
  require("../../assets/frames_world/ezgif-frame-040.jpg"),
  require("../../assets/frames_world/ezgif-frame-041.jpg"),
];
