import * as React from "react";

import { Button } from "./Button";
import { Modal, ModalProps } from "./Modal";

type Props = ModalProps & {
  title: string;
  errorMessage?: string;
  buttonText?: string;
  buttonDisabled?: boolean;
  onButtonClick?(e: React.MouseEvent<HTMLButtonElement>): void;
};

export const SimpleModal = ({
  title,
  onButtonClick,
  children,
  buttonText = "Ok",
  buttonDisabled = false,
  errorMessage,
  ...props
}: React.PropsWithChildren<Props>) => {
  return (
    <Modal title={title} className="max-w-[400px]" {...props}>
      {children}
      <Button
        className="flex-grow mt-5"
        disabled={buttonDisabled}
        onClick={(e) => {
          e.preventDefault();
          if (onButtonClick) {
            onButtonClick(e);
          }
        }}
      >
        {buttonText}
      </Button>
      {!!errorMessage && (
        <span className="mt-2 self-center text-error">{errorMessage}</span>
      )}
    </Modal>
  );
};
