import React from "react";

export const ScrollVideo: React.FC<{ frames: string[] }> = ({ frames }) => {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const frameCount = frames.length;
    const currentFrame = (index: number) => frames[index];

    const preloadImages = () => {
      for (let i = 1; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
      }
    };

    let currentIndex = 0;
    const context = canvas.current!.getContext("2d")!;
    const img = new Image();
    img.src = currentFrame(0);
    const w = divRef.current!.offsetWidth;
    const h = divRef.current!.offsetHeight;
    canvas.current!.width = w;
    canvas.current!.height = h;
    img.onload = function () {
      updateImage(frameFromScroll());
    };

    const frameFromScroll = () => {
      const rect = canvas.current?.getBoundingClientRect()!;
      if (!rect) return 0;

      const progress =
        (window.innerHeight - rect.top) / (window.innerHeight + rect.height);

      return Math.max(
        0,
        Math.min(frameCount - 1, Math.ceil(progress * frameCount))
      );
    };

    const updateImage = (index: number) => {
      const currentCanvas = canvas.current;
      if (!currentCanvas) return;

      const currentDiv = divRef.current;
      if (!currentDiv) return;

      img.onload = () => {};

      index = Math.min(frameCount - 1, Math.max(index, 0));
      const canvasWidth = currentDiv.offsetWidth;
      const canvasHeight = currentDiv.offsetHeight;
      if (canvasWidth <= 0 || canvasHeight <= 0) return;
      if (
        currentCanvas.width !== canvasWidth ||
        currentCanvas.height !== canvasHeight
      ) {
        const temp = context.getImageData(
          0,
          0,
          currentCanvas.width,
          currentCanvas.height
        );
        currentCanvas.width = canvasWidth;
        currentCanvas.height = canvasHeight;
        context.putImageData(temp, 0, 0);
      }

      currentIndex = index;
      img.src = currentFrame(index);
      img.onload = () => {
        const imgAspectRatio = img.naturalWidth / img.naturalHeight;
        if (Number.isNaN(imgAspectRatio)) return;

        const canvasAspectRatio = canvasWidth / canvasHeight;

        let drawWidth, drawHeight, offsetX, offsetY;

        // Determine how to scale the image
        if (imgAspectRatio > canvasAspectRatio) {
          // Image is wider than canvas
          drawHeight = canvasHeight;
          drawWidth = canvasHeight * imgAspectRatio;
          offsetX = (canvasWidth - drawWidth) / 2;
          offsetY = 0;
        } else {
          // Image is taller or the same aspect ratio as canvas
          drawWidth = canvasWidth;
          drawHeight = canvasWidth / imgAspectRatio;
          offsetX = 0;
          offsetY = (canvasHeight - drawHeight) / 2;
        }

        // console.log(
        //   img.naturalWidth,
        //   img.naturalHeight,
        //   offsetX,
        //   offsetY,
        //   drawWidth,
        //   drawHeight
        // );

        context.drawImage(
          img,
          0, // Source X
          0, // Source Y
          img.naturalWidth, // Source Width
          img.naturalHeight, // Source Height
          offsetX, // Destination X
          offsetY, // Destination Y
          drawWidth, // Destination Width
          drawHeight // Destination Height
        );
      };
    };

    const onScroll = () => {
      if (!canvas.current) return;

      const frameIndex = frameFromScroll();
      if (frameIndex !== currentIndex) {
        requestAnimationFrame(() => updateImage(frameIndex));
      }
    };

    const onResize = () => {
      updateImage(currentIndex);
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    preloadImages();

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <div ref={divRef} className="absolute inset-0">
      <canvas ref={canvas} className="absolute inset-0" />
    </div>
  );
};
