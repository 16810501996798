import React, { useEffect } from "react";

import { PageFooter } from "../PageFooter";
import { CombatSection } from "./CombatSection";
import { WorldSection } from "./WorldSection";
import { StorySection } from "./StorySection";
import { CommunitySection } from "./CommunitySection";
import { BetaSignUpSection } from "./BetaSignUpSection";
import { EconomySection } from "./EconomySection";
import { VideoSection } from "./VideoSection";
import { TopSection } from "./TopSection";
import { DiscordSection } from "./DiscordSection";
import { SkillsSection } from "./SkillsSection";
import { Button } from "../common/Button";
import { useNavigate } from "react-router-dom";
import { DefaultRoutes } from "../../DefaultRoutes";

export const LandingPage: React.FC = () => {
  return (
    <div className="relative w-full">
      <HomeBar />
      <TopSection />
      <VideoSection />
      <DiscordSection />
      <StorySection />
      <CommunitySection />
      <WorldSection />
      <EconomySection />
      <CombatSection />
      <SkillsSection />
      <BetaSignUpSection />
      <PageFooter />
    </div>
  );
};

const HomeBar = () => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    const callback = () => {
      if (window.scrollY > 300) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener("scroll", callback);

    return () => {
      window.removeEventListener("scroll", callback);
    };
  }, []);

  return (
    <div
      className={
        "fixed bg-[rgb(23,47,102,0.88)] top-4 right-4 z-50 p-2 flex flex-row gap-10 border-black border-2 transition-all" +
        (show ? " opacity-1" : " opacity-0")
      }
    >
      <div
        className="bg-cover cursor-pointer"
        style={{
          backgroundImage: "url(" + require("../../assets/house-128.png") + ")",
          width: 32,
          height: 32,
        }}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <Button
        onClick={() => {
          navigate(DefaultRoutes.signUp);
        }}
        className="mt-0"
      >
        Sign Up
      </Button>
    </div>
  );
};
