import { ModalProps } from "../../common/Modal";
import { Paragraph } from "../../common/Paraprah";
import { SimpleModal } from "../../common/SimpleModal";

export const CancelSubscriptionSuccessModal = (props: ModalProps) => {
  return (
    <SimpleModal
      {...props}
      title="Subscription Cancelled!"
      onButtonClick={props.onExit}
    >
      <Paragraph>
        {"Your subscription has been successfully cancelled!"}
      </Paragraph>
    </SimpleModal>
  );
};
