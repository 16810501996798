import { TwoColumnSection } from "./TwoColumnSection";

import section1TitleImage from "../../assets/story_color.png";
import { TextList } from "../common/TextList";

export const StorySection = () => {
  return (
    <TwoColumnSection
      reverseColumns
      scrollFrames={frames}
      titleImageSrc={section1TitleImage}
      titleImageAlt="Fight Monsters"
      trackingContentType={"LandingPage_Section1Visible"}
    >
      <div className="text-dark md:text-light my-4 responsive-font-size">
        <TextList>
          <span>
            Fight epic <strong>Bosses</strong>.
          </span>

          <span>
            <strong>Branching Quests</strong> where you decide the ending.
          </span>

          <span>
            Build <strong>NPC Relationships</strong>.
          </span>

          <span>
            Rewrite history in this <strong>Time Travel</strong> adventure
          </span>
        </TextList>
      </div>
    </TwoColumnSection>
  );
};

const frames = [
  require("../../assets/frames_story/ezgif-frame-001.jpg"),
  require("../../assets/frames_story/ezgif-frame-002.jpg"),
  require("../../assets/frames_story/ezgif-frame-003.jpg"),
  require("../../assets/frames_story/ezgif-frame-004.jpg"),
  require("../../assets/frames_story/ezgif-frame-005.jpg"),
  require("../../assets/frames_story/ezgif-frame-006.jpg"),
  require("../../assets/frames_story/ezgif-frame-007.jpg"),
  require("../../assets/frames_story/ezgif-frame-008.jpg"),
  require("../../assets/frames_story/ezgif-frame-009.jpg"),
  require("../../assets/frames_story/ezgif-frame-010.jpg"),
  require("../../assets/frames_story/ezgif-frame-011.jpg"),
  require("../../assets/frames_story/ezgif-frame-012.jpg"),
  require("../../assets/frames_story/ezgif-frame-013.jpg"),
  require("../../assets/frames_story/ezgif-frame-014.jpg"),
  require("../../assets/frames_story/ezgif-frame-015.jpg"),
  require("../../assets/frames_story/ezgif-frame-016.jpg"),
  require("../../assets/frames_story/ezgif-frame-017.jpg"),
  require("../../assets/frames_story/ezgif-frame-018.jpg"),
  require("../../assets/frames_story/ezgif-frame-019.jpg"),
  require("../../assets/frames_story/ezgif-frame-020.jpg"),
  require("../../assets/frames_story/ezgif-frame-021.jpg"),
  require("../../assets/frames_story/ezgif-frame-022.jpg"),
  require("../../assets/frames_story/ezgif-frame-023.jpg"),
  require("../../assets/frames_story/ezgif-frame-024.jpg"),
  require("../../assets/frames_story/ezgif-frame-025.jpg"),
  require("../../assets/frames_story/ezgif-frame-026.jpg"),
  require("../../assets/frames_story/ezgif-frame-027.jpg"),
  require("../../assets/frames_story/ezgif-frame-028.jpg"),
  require("../../assets/frames_story/ezgif-frame-029.jpg"),
  require("../../assets/frames_story/ezgif-frame-030.jpg"),
  require("../../assets/frames_story/ezgif-frame-031.jpg"),
  require("../../assets/frames_story/ezgif-frame-032.jpg"),
  require("../../assets/frames_story/ezgif-frame-033.jpg"),
  require("../../assets/frames_story/ezgif-frame-034.jpg"),
  require("../../assets/frames_story/ezgif-frame-035.jpg"),
  require("../../assets/frames_story/ezgif-frame-036.jpg"),
  require("../../assets/frames_story/ezgif-frame-037.jpg"),
  require("../../assets/frames_story/ezgif-frame-038.jpg"),
  require("../../assets/frames_story/ezgif-frame-039.jpg"),
  require("../../assets/frames_story/ezgif-frame-040.jpg"),
  require("../../assets/frames_story/ezgif-frame-041.jpg"),
  require("../../assets/frames_story/ezgif-frame-042.jpg"),
];
