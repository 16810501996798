import { useEffect, useState } from "react";
import { DefaultRoutes } from "../../DefaultRoutes";
import { TexturedButton } from "../common/TexturedButton";
import { TrackedLink } from "../utility/TrackedLink";

export const TopSection = () => {
  const { done, countdown } = useCountdown(revealDate);

  return (
    <div
      className="relative h-0 overflow-hidden bg-cover md:min-h-[400px] min-h-[360px] flex flex-col justify-end bg-center"
      style={{
        backgroundImage: "url(" + require("../../assets/top_bg.png") + ")",
      }}
    >
      <div
        className="absolute top-0 left-0 w-[30vw] h-[19vw] max-w-[360px] max-h-[228px] bg-contain bg-no-repeat"
        style={{
          backgroundImage: "url(" + require("../../assets/logo.png") + ")",
        }}
      />
      <div
        className={
          "flex items-center gap-5 self-center mb-10 flex-col" +
          (done ? " lg:flex-row lg:ml-[170px] lg:gap-16" : " lg:ml-[400px]")
        }
      >
        {!done && <Countdown countdown={countdown} />}
        {done && <BlogPostLink />}
        <BetaSignUpButton />
      </div>
    </div>
  );
};
const revealDate = new Date("2024-04-01");

const Countdown = (props: { countdown: string }) => {
  return (
    <div className="bg-[rgb(23,47,102,0.88)] border-[#FFD966] border-2 p-2 text-2xl">
      <div className="text-slate-50">Beta Details coming</div>
      <div className="text-slate-50 text-center font-bold">
        {props.countdown}
      </div>
    </div>
  );
};

const BlogPostLink = () => {
  return (
    <TrackedLink
      className=""
      to={DefaultRoutes.blog}
      trackingName={"LandingPage_BlogClicked"}
    >
      <div className="bg-[rgb(23,47,102,0.88)] border-[#FFD966] border-2 p-2 text-2xl">
        <div className="text-slate-50">Click here to check out </div>
        <div className="text-slate-50 text-center font-bold">
          our new blog post!
        </div>
      </div>
    </TrackedLink>
  );
};

const BetaSignUpButton = () => {
  return (
    <TrackedLink
      className=""
      to={DefaultRoutes.signUp}
      trackingName={"LandingPage_BetaSignUpVideoClicked"}
    >
      <TexturedButton
        width={undefined}
        height={undefined}
        bgSrc={require("../../assets/betasignup.png")}
        bgHoverSrc={require("../../assets/betasignup_hover.png")}
        bgActiveSrc={require("../../assets/betasignup_press.png")}
        className="w-[277.5px] h-[72px] sm:w-[370px] sm:h-[96px] md:w-[440px] md:h-[113px]"
      />
    </TrackedLink>
  );
};

const useCountdown = (targetDate: Date) => {
  // Initialize state with the countdown time
  const [countdown, setCountdown] = useState<number>(
    targetDate.getTime() - new Date().getTime()
  );

  useEffect(() => {
    // Set up an interval to decrement the countdown
    const interval = setInterval(() => {
      const delta = Math.max(targetDate.getTime() - new Date().getTime(), 0);
      setCountdown(delta);
      if (delta <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);

  // Convert countdown from milliseconds to a more readable format
  const getReadableCountdown = (): string => {
    const totalSeconds = Math.floor(countdown / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}d:${hours}h:${minutes}m:${seconds}s`;
  };

  return { done: countdown <= 0, countdown: getReadableCountdown() };
};
