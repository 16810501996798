import { useTrackEvent } from "../utility/useTrackEvent";

export const TextLink = (props: {
  children: React.ReactNode;
  href: string;
  trackingEvent?: string;
}) => {
  const trackEvent = useTrackEvent();

  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      className="text-blue-500 hover:underline"
      onClick={
        props.trackingEvent ? () => trackEvent(props.trackingEvent!) : undefined
      }
    >
      {props.children}
    </a>
  );
};
