import { Endpoints } from "../Endpoints";
import { usePostRequest } from "./usePostRequest";

type CancelSubscriptionResponse = { error?: CancelSubscriptionError };

export enum CancelSubscriptionError {
  Internal = "Internal",
}

export const useCancelSubscriptionRequest = () => {
  const post = usePostRequest();

  return async (): Promise<CancelSubscriptionResponse> => {
    const response = await post(Endpoints.cancelSubscription);

    if (!response || !response.ok) {
      return {
        error: CancelSubscriptionError.Internal,
      };
    }

    return {};
  };
};
