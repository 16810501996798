import * as React from "react";
import logo from "../assets/logo.png";
import background from "../assets/top_bg.png";
import menu from "../assets/menu.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useNavigate } from "react-router-dom";
import { TrackedLink } from "./utility/TrackedLink";
import { DefaultRoutes } from "../DefaultRoutes";

type Props = {
  disableLogoClick?: boolean;
  hideMenu?: boolean;
};

export const PageHeader: React.FC<Props> = ({ disableLogoClick, hideMenu }) => {
  return (
    <div
      className="flex flex-row bg-cover justify-between w-full"
      style={{
        backgroundImage: "url(" + background + ")",
      }}
    >
      <Logo clickable={!disableLogoClick} />
      {!hideMenu && <PageHeaderMenu />}
    </div>
  );
};

const Logo: React.FC<{ clickable: boolean }> = ({ clickable }) => {
  return clickable ? (
    <TrackedLink to={"/"} trackingName={"PageHeader_LogoClicked"}>
      <img src={logo} alt={"logo"} className="w-[35vw] max-w-[400px]" />
    </TrackedLink>
  ) : (
    <img src={logo} alt={"logo"} className="w-[35vw] max-w-[400px]" />
  );
};

const PageHeaderMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = (e: React.MouseEvent) => {
    setAnchorEl(e.target as any);
  };

  const openHome = () => {
    navigate(DefaultRoutes.landingPage);
  };

  const openSignUp = () => {
    navigate(DefaultRoutes.signUp);
  };

  const openAccount = () => {
    navigate(DefaultRoutes.account);
  };

  return (
    <>
      <div
        className="w-16 h-16 cursor-pointer brightness-95 hover:brightness-100 transition"
        style={{ backgroundImage: "url(" + menu + ")" }}
        onClick={openMenu}
      />
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClick={closeMenu}
        onClose={closeMenu}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
      >
        <MenuItem onClick={openHome}>Home</MenuItem>
        <MenuItem onClick={openSignUp}>Sign Up</MenuItem>
        <MenuItem onClick={openAccount}>Account</MenuItem>
      </Menu>
    </>
  );
};
