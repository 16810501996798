import { useEffect } from "react";
import { useAuthContext, useAuthToken } from "./AuthContext";
import { useValidateTokenRequest } from "../requests/useValidateTokenRequest";
const TOKEN_KEY = "userToken";

export const useLoadAndPersistAuthToken = () => {
  const { setState, state } = useAuthContext();
  const validateToken = useValidateToken();

  useListenForStorageEvent();
  useRevalidateToken();

  useEffect(() => {
    const token = getPersistedToken();
    if (!token) {
      setState({ type: "unauthenticated" });
      return;
    }

    validateToken(token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.type === "authenticated") {
      setPersistedToken(state.token);
    } else {
      setPersistedToken(null);
    }
  }, [state]);
};

function getPersistedToken(): string | null {
  return localStorage.getItem(TOKEN_KEY);
}

function setPersistedToken(token: string | null): void {
  if (token) {
    localStorage.setItem(TOKEN_KEY, token);
  } else {
    localStorage.removeItem(TOKEN_KEY);
  }
}

const useListenForStorageEvent = () => {
  const { setState } = useAuthContext();

  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key === TOKEN_KEY) {
        if (!e.newValue) {
          setState({ type: "unauthenticated" });
        }
      }
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [setState]);
};

const useValidateToken = () => {
  const { setState } = useAuthContext();
  const validateToken = useValidateTokenRequest();

  return (token: string) => {
    validateToken(token).then((response) => {
      if (response.valid) {
        setState({ type: "authenticated", token });
      } else {
        setState({ type: "unauthenticated" });
        localStorage.removeItem(TOKEN_KEY);
      }
    });
  };
};

const useRevalidateToken = () => {
  const token = useAuthToken();
  const validateToken = useValidateToken();

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (token) {
          validateToken(token);
        }
      },
      2 * 60 * 1000
    );

    return () => {
      clearInterval(interval);
    };
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps
};
