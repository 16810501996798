const origins = {
  local: "http://localhost:8080/",
  production: "https://chronocraftgame.com/backend/",
  test: "https://test.chronocraftgame.com/backend/",
};

const endpoints = {
  login: "login",
  resetPassword: "user/resetpassword",
  changePassword: "user/changepassword",
  accountInfo: "user/account",
  location: "location",
  cancelSubscription: "subscription/cancel",
  validateToken: "tokens/validate",
  braintreeClientToken: "braintree/token",
  createCustomer: "sign-up/create-customer",
  signUp: "sign-up/sign-up",
  updatePaymentMethod: "subscription/update-payment-method",
  updateBillingInfo: "customer/update-billing-info",
  resubscribe: "subscription/resubscribe",
  initialPricing: "pricing/initial",
  checkoutPricing: "pricing/checkout",
  changeEmail: "user/changeemail",
  betaSignUp: "beta-sign-up/sign-up",
};

const activeOrigin = getActiveOrigin();
export const Endpoints = addOrigin(endpoints);

function addOrigin<T>(input: { [key in keyof T]: string }): {
  [key in keyof T]: string;
} {
  const result: { [key: string]: string } = {};

  Object.keys(input).forEach(
    (key) => (result[key] = (activeOrigin + (input as any)[key]) as string)
  );

  return result as { [key in keyof T]: string };
}

function getActiveOrigin() {
  if (process.env.NODE_ENV !== "production") {
    return origins.local;
  }

  if (process.env.REACT_APP_IS_TEST_DEPLOYMENT === "true") {
    return origins.test;
  }

  return origins.production;
}
