import { SubscriptionStatus } from "../../state/SubscriptionStatus";

export const SubscriptionStatusComp = ({
  status,
}: {
  status: SubscriptionStatus;
}) => {
  let text: string;
  switch (status) {
    case "ACTIVE":
      text = "Active";
      break;

    case "INACTIVE":
      text = "InActive";
      break;

    case "CANCELLED":
      text = "Cancelled";
      break;
  }

  return (
    <div className="flex flex-row">
      <div className="border-solid border-[3px] border-gray-500 p-2 flex-grow-0 self-start justify-self-start">
        Subscription:{" "}
        <span
          className={`font-bold ${
            status === "ACTIVE" ? "text-green-700" : "text-red-500"
          }`}
        >
          {text}
        </span>
      </div>
    </div>
  );
};
