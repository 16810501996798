export const Title = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h2 className={`font-bold text-2xl my-4 ${props.className ?? ""}`}>
      {props.children}
    </h2>
  );
};
