import { MouseEvent } from "react";

export const Button = (props: {
    children: React.ReactNode;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    className?: string;
}) => {
    return (
        <button
            className={`p-2 cursor-pointer disabled:cursor-default rounded border
            border-gray-500 border-solid bg-gray-200 hover:bg-gray-300
            disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-500
            text-xs transition ${props.className ?? ''}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
}