import * as React from "react";
import { PageHeader } from "./PageHeader";
import { PageFooter } from "./PageFooter";

type Props = {
  hideFooter?: boolean;
  disableLogoClick?: boolean;
  hideMenu?: boolean;
  hideHeader?: boolean;
};

export const Page = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className="flex flex-col flex-grow w-full">
      {!props.hideHeader && (
        <PageHeader
          disableLogoClick={props.disableLogoClick}
          hideMenu={props.hideMenu}
        />
      )}
      {props.children}
      <div className="flex-grow" />
      <PageFooter hideLinks={props.hideFooter} />
    </div>
  );
};
