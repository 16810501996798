import React, { createContext, useMemo, useReducer } from "react";
import { mapObjectValues } from "../tools/mapObjectValues";

export type Validator<TValues extends object> = (
  value: TValues[keyof TValues],
  allValues: TValues
) =>
  | true
  | {
      validity: "potentiallyValid" | "invalid";
      errorMessage: string;
    };

type Validators<TValues extends object> = Partial<
  Record<keyof TValues, Validator<TValues>>
>;

export function createInputContext<TValues extends object>(
  initialValues: TValues,
  validators: Validators<TValues> = {}
) {
  type Errors = Partial<Record<keyof TValues, string>>;

  type Action =
    | { kind: "setValues"; values: Partial<TValues> }
    | { kind: "editValues"; values: Partial<TValues> }
    | { kind: "validateAll" }
    | { kind: "validate"; field: keyof TValues };

  type ReducerState = {
    values: TValues;
    errors: Errors;
  };

  const mapValidatorsToErrors = (
    newValues: Partial<TValues>,
    allValues: TValues,
    potentiallyValidIsInvalid: boolean,
    emptyIsInvalid: boolean = false
  ) => {
    return mapObjectValues(newValues, (value, key) => {
      if (!value) {
        if (emptyIsInvalid) {
          return "Must be filled in.";
        } else {
          return null;
        }
      }

      const validator = (validators as any)[key] as Validator<Partial<TValues>>;
      const result = validator ? validator(value, allValues) : true;
      if (result === true) return null;
      switch (result.validity) {
        case "potentiallyValid":
          return potentiallyValidIsInvalid ? result.errorMessage : null;
        case "invalid":
          return result.errorMessage;
      }
    });
  };

  const reducer = (state: ReducerState, action: Action): ReducerState => {
    switch (action.kind) {
      case "setValues":
        return {
          values: {
            ...state.values,
            ...action.values,
          },
          errors: {
            ...state.errors,
            ...mapValidatorsToErrors(
              action.values,
              {
                ...state.values,
                ...action.values,
              },
              true
            ),
          },
        };
      case "editValues":
        return {
          values: {
            ...state.values,
            ...action.values,
          },
          errors: {
            ...state.errors,
            ...mapValidatorsToErrors(
              action.values,
              {
                ...state.values,
                ...action.values,
              },
              false
            ),
          },
        };
      case "validateAll":
        return {
          values: state.values,
          errors: {
            ...state.errors,
            ...mapValidatorsToErrors(state.values, state.values, true, true),
          },
        };
      case "validate":
        return {
          values: state.values,
          errors: {
            ...state.errors,
            ...mapValidatorsToErrors(
              {
                [action.field]: state.values[action.field],
              } as Partial<TValues>,
              state.values,
              true
            ),
          },
        };
      default:
        return state;
    }
  };

  type InputContextType = TValues & {
    errors: Errors;
    dispatch: React.Dispatch<Action>;
    validateAll: () => boolean;
  };

  const context = createContext<InputContextType>({
    ...initialValues,
    errors: {},
    dispatch: () => {
      throw new Error("dispatch not implemented");
    },
    validateAll: () => false,
  });

  const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
      values: initialValues,
      errors: {},
    });

    const value: InputContextType = useMemo(() => {
      return {
        ...state.values,
        errors: state.errors,
        dispatch,
        validateAll: () => {
          dispatch({ kind: "validateAll" });
          return !Object.entries(state.values).some(([key, value]) => {
            if (!value) return true;
            const validator = (validators as any)[key] as Validator<
              Partial<TValues>
            >;
            const result = validator ? validator(value, state.values) : true;
            return result !== true;
          });
        },
      };
    }, [state, dispatch]);

    return <context.Provider value={value}>{children}</context.Provider>;
  };

  return {
    context,
    Provider,
  };
}
