import * as React from "react";

import { useAuthContext } from "../../state/AuthContext";
import { useTrackEvent } from "../utility/useTrackEvent";
import { ChangeEmailModal } from "./ChangeEmailModal";
import { SupportEmailLink } from "../common/SupportEmailLink";
import { Title } from "../common/Title";
import { Paragraph } from "../common/Paraprah";
import { LineDivider } from "../common/LineDivider";
import { SubscriptionStatusComp } from "./SubscriptionStatus";
import { useAccountInfoContext } from "../../state/AccountInfoContext";
import { ChangeSubscriptionStatus } from "./ChangeSubscriptionStatus";
import { BillingInputContextProvider } from "../../state/BillingInputContext";

export const ManageAccount = () => {
  return (
    <div className="flex flex-col content-between flex-grow md:flex-row">
      <div className="md:flex-grow md:flex-shrink" />
      <BillingInputContextProvider>
        <AccountSection />
      </BillingInputContextProvider>
      <div className="h-8 md:flex-grow md:flex-shrink" />
      <HelpSection />
    </div>
  );
};

const AccountSection = () => {
  const { setState } = useAuthContext();
  const { setInfo } = useAccountInfoContext();
  const logout = () => {
    setState({ type: "unauthenticated" });
    setInfo(null);
  };

  const { info } = useAccountInfoContext();
  if (!info) {
    return null;
  }

  return (
    <div
      className="flex-grow-[2] flex-shrink-0 min-w-[35vw]"
      id="account-section"
    >
      <Title className="text-gray-600">My Account</Title>
      <LineDivider />
      <ul className="list-disc pl-10">
        <ChangeEmail email={info.email} />
        {info.subscriptionStatus === "ACTIVE" && (
          <li className="my-4">
            <ChangeSubscriptionStatus status={info.subscriptionStatus} />
          </li>
        )}
        <li className="my-4">
          <span className="cursor-pointer text-blue-500" onClick={logout}>
            Log out
          </span>
        </li>
      </ul>
      <SubscriptionStatusComp status={info.subscriptionStatus} />
    </div>
  );
};

const ChangeEmail = ({ email }: { email: string }) => {
  const [isModalVisible, setModalVisible] = React.useState(false);
  const trackEvent = useTrackEvent();

  const onClick = (): void => {
    trackEvent("ManageAccount_ChangeEmailClicked");
    setModalVisible(true);
  };

  return (
    <>
      <li className="my-4">
        <span onClick={onClick} className="cursor-pointer text-blue-500">
          Change email
        </span>{" "}
        <span className="text-dark">({email})</span>
      </li>
      {isModalVisible && (
        <ChangeEmailModal
          mounted={isModalVisible}
          onExit={() => setModalVisible(false)}
          currentEmail={email}
        />
      )}
    </>
  );
};

const HelpSection = () => {
  return (
    <div>
      <Title className="text-gray-600">Need Help?</Title>
      <LineDivider />
      <Paragraph className="text-dark">
        Email customer support at
        <br />
        <SupportEmailLink />
      </Paragraph>
    </div>
  );
};
