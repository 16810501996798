import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ImageTitle } from "../common/ImageTitle";
import { useWasVisibleOnce } from "../utility/useIsVisible";
import { useTrackEvent } from "../utility/useTrackEvent";
import { LandingPageSection } from "./LandingPageSection";
import { ScrollVideo } from "./ScrollVideo";

interface Props {
  hasShadow?: boolean;
  reverseColumns?: boolean;
  backgroundImageSrc?: string;
  titleImageSrc: string;
  titleImageAlt: string;
  trackingContentType?: string;
  scrollFrames: string[];
}

export const TwoColumnSection = (props: React.PropsWithChildren<Props>) => {
  const [wasVisibleOnce, domRef] = useWasVisibleOnce<HTMLDivElement>();
  const trackEvent = useTrackEvent();
  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    if (wasVisibleOnce && props.trackingContentType) {
      trackEvent(props.trackingContentType);
    }
  }, [wasVisibleOnce, props.trackingContentType, trackEvent]);

  const imageColumn = (
    <div className="relative flex flex-1 md:justify-center w-full ">
      {isSmallScreen && (
        <div className="relative w-full bg-cover pb-[56.25%]">
          <ScrollVideo frames={props.scrollFrames} />
        </div>
      )}
    </div>
  );

  const textColumn = (
    <div className="relative flex flex-[0.75] flex-col mt-[15px] md:mt-0 md:min-h-[400px] lg:min-h-[500px] xl:min-h-[600px] 2xl:min-h-[800px] justify-center">
      <ImageTitle
        className={"self-center z-10 mt-5 md:mt-0"}
        src={props.titleImageSrc}
        alt={props.titleImageAlt}
      />
      <div className="relative">
        {props.reverseColumns ? (
          <div className="bg-[rgba(0,0,0,0.77)] absolute -inset-y-5 -left-10 -right-10 md:-right-5 hidden md:block" />
        ) : (
          <div className="bg-[rgba(0,0,0,0.77)] absolute -inset-y-5 -right-10 -left-10 md:-left-5 hidden md:block" />
        )}
        <div className="relative">{props.children}</div>
      </div>
    </div>
  );

  let wrapperClasses = "relative flex flex-col md:flex-row";
  if (props.reverseColumns) {
    wrapperClasses += " md:flex-row-reverse";
  }

  return (
    <LandingPageSection
      backgroundImageSrc={props.backgroundImageSrc}
      hasShadow={true}
    >
      {!isSmallScreen && <ScrollVideo frames={props.scrollFrames} />}
      <div ref={domRef} className={wrapperClasses}>
        {imageColumn}
        {textColumn}
      </div>
    </LandingPageSection>
  );
};

const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.innerHeight < 768
  );

  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isSmallScreen;
};
