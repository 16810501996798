import { TwoColumnSection } from "./TwoColumnSection";

import background3 from "../../assets/signup_bg.png";
import section4TitleImage from "../../assets/skill_color.png";
import { TextList } from "../common/TextList";

export const SkillsSection = () => {
  return (
    <TwoColumnSection
      hasShadow
      backgroundImageSrc={background3}
      scrollFrames={frames}
      titleImageSrc={section4TitleImage}
      titleImageAlt="Master New Skills"
      trackingContentType={"LandingPage_Section4Visible"}
    >
      <div className="text-light my-4 responsive-font-size">
        <TextList>
          <span>
            <strong>Cooking</strong> - Unlock better tools & kitchens.
          </span>
          <span>
            <strong>Fishing</strong> - Uncover secret fishing spot & tools.
          </span>
          <span>
            <strong>Mining</strong> - Unlock rare dig sites and supplies.
          </span>
          <span>
            <strong>Foraging</strong> - Discover unique plants & critters.
          </span>
          <span>
            <strong>Mana-facturing</strong> - Make magical runic weapons.
          </span>
          <span>
            <strong>Fletching</strong> - Craft dangerous ranged weapons.
          </span>
          <span>
            <strong>Smithing</strong> - Forge powerful melee weapons.
          </span>
          <span>
            <strong>Farming</strong> - Grow & sell your fresh vegetables.
          </span>
          <span>
            <strong>Prestige</strong> - Unlock adventures and more.
          </span>
        </TextList>
      </div>
    </TwoColumnSection>
  );
};

const frames = [
  require("../../assets/frames_skill/ezgif-frame-001.jpg"),
  require("../../assets/frames_skill/ezgif-frame-002.jpg"),
  require("../../assets/frames_skill/ezgif-frame-003.jpg"),
  require("../../assets/frames_skill/ezgif-frame-004.jpg"),
  require("../../assets/frames_skill/ezgif-frame-005.jpg"),
  require("../../assets/frames_skill/ezgif-frame-006.jpg"),
  require("../../assets/frames_skill/ezgif-frame-007.jpg"),
  require("../../assets/frames_skill/ezgif-frame-008.jpg"),
  require("../../assets/frames_skill/ezgif-frame-009.jpg"),
  require("../../assets/frames_skill/ezgif-frame-010.jpg"),
  require("../../assets/frames_skill/ezgif-frame-011.jpg"),
  require("../../assets/frames_skill/ezgif-frame-012.jpg"),
  require("../../assets/frames_skill/ezgif-frame-013.jpg"),
  require("../../assets/frames_skill/ezgif-frame-014.jpg"),
  require("../../assets/frames_skill/ezgif-frame-015.jpg"),
  require("../../assets/frames_skill/ezgif-frame-016.jpg"),
  require("../../assets/frames_skill/ezgif-frame-017.jpg"),
  require("../../assets/frames_skill/ezgif-frame-018.jpg"),
  require("../../assets/frames_skill/ezgif-frame-019.jpg"),
  require("../../assets/frames_skill/ezgif-frame-020.jpg"),
  require("../../assets/frames_skill/ezgif-frame-021.jpg"),
  require("../../assets/frames_skill/ezgif-frame-022.jpg"),
  require("../../assets/frames_skill/ezgif-frame-023.jpg"),
  require("../../assets/frames_skill/ezgif-frame-024.jpg"),
  require("../../assets/frames_skill/ezgif-frame-025.jpg"),
  require("../../assets/frames_skill/ezgif-frame-026.jpg"),
  require("../../assets/frames_skill/ezgif-frame-027.jpg"),
  require("../../assets/frames_skill/ezgif-frame-028.jpg"),
  require("../../assets/frames_skill/ezgif-frame-029.jpg"),
  require("../../assets/frames_skill/ezgif-frame-030.jpg"),
  require("../../assets/frames_skill/ezgif-frame-031.jpg"),
  require("../../assets/frames_skill/ezgif-frame-032.jpg"),
  require("../../assets/frames_skill/ezgif-frame-033.jpg"),
  require("../../assets/frames_skill/ezgif-frame-034.jpg"),
  require("../../assets/frames_skill/ezgif-frame-035.jpg"),
  require("../../assets/frames_skill/ezgif-frame-036.jpg"),
  require("../../assets/frames_skill/ezgif-frame-037.jpg"),
  require("../../assets/frames_skill/ezgif-frame-038.jpg"),
  require("../../assets/frames_skill/ezgif-frame-039.jpg"),
];
