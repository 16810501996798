import { useCallback, useEffect, useState } from "react";
import { ConfirmModal } from "../../common/ConfirmModal";
import { ModalProps } from "../../common/Modal";
import {
  CancelSubscriptionError,
  useCancelSubscriptionRequest,
} from "../../../requests/useCancelSubscriptionRequest";
import { Result } from "../../../state/Result";
import { ErrorOverlay, InProgressOverlay } from "../../common/ModalOverlays";
import { useRefetchtAccountInfo } from "../../../tools/useRefetchAccountInfo";
import { useTrackEvent } from "../../utility/useTrackEvent";

type Props = ModalProps & { onExit(): void; onSuccess(): void };

export const CancelSubscriptionModal = ({
  onExit,
  onSuccess,
  ...props
}: Props) => {
  const { cancelSubscription, status, reset } = useCancelSubscription();

  useEffect(() => {
    if (status === "success") {
      onSuccess();
    }
  }, [status, onSuccess]);

  return (
    <ConfirmModal
      {...props}
      title={"Cancel Subscription"}
      message="Are you sure you want to cancel your subscription?"
      onCancel={onExit}
      onConfirm={cancelSubscription}
    >
      {status === "loading" && <InProgressOverlay type={'modal'} />}
      {typeof status === "object" && (
        <ErrorOverlay type={'modal'} error={status.error} onClose={reset} />
      )}
    </ConfirmModal>
  );
};

const useCancelSubscription = () => {
  const executeCancelSubscription = useExecuteCancelSubscription();
  const refetchAccountInfo = useRefetchtAccountInfo();
  const trackEvent = useTrackEvent();

  const [status, setStatus] = useState<
    "ready" | "loading" | { error: string } | "success"
  >("ready");

  const cancelSubscription = async () => {
    trackEvent("CancelSubscriptionModal_CancelClicked");

    if (status !== "ready") {
      return;
    }

    setStatus("loading");

    const result = await executeCancelSubscription();

    if (result === "success") {
      refetchAccountInfo();
      setStatus("success");
      trackEvent("CancelSubscriptionModal_Result_Success");
    } else {
      setStatus({ error: result.errorMessage });
      trackEvent("CancelSubscriptionModal_Result_" + result.errorCode);
    }
  };

  const reset = useCallback(() => {
    setStatus("ready");
  }, [setStatus]);

  return { cancelSubscription, status, reset };
};

const useExecuteCancelSubscription = () => {
  const cancelSubscription = useCancelSubscriptionRequest();

  return async () => {
    const response = await cancelSubscription();

    if (response.error) {
      return cancelSubscriptionErrorToResult(response.error);
    }

    return "success";
  };
};

function cancelSubscriptionErrorToResult(
  error: CancelSubscriptionError
): Exclude<Result, "success"> {
  switch (error) {
    case CancelSubscriptionError.Internal:
      return {
        errorCode: "internal",
        errorMessage: "There was an internal error. Please try again later.",
      };
    default:
      const missingCase: never = error;
      return missingCase;
  }
}
