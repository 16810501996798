import { useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { DefaultRoutes } from "../../DefaultRoutes";
import { Endpoints } from "../../Endpoints";
import { safeFetch } from "../../tools/safeFetch";
import { SignUpButton } from "../SubscriptionModals/SignUpButton";
import { FormInput } from "../common/FormInput";
import { Paragraph } from "../common/Paraprah";
import {
  IconDiscord,
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconTwitter,
  IconYouTube,
} from "../common/SocialIcons";
import { Title } from "../common/Title";

export const BetaSignUpForm = () => {
  const [email, setEmail] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [emailError, setEmailError] = useState("");

  const { signUp, signingUp, errorMessage, success } = useSignUp();

  return (
    <div className="relative flex flex-col max-w-[500px] mt-16 h-[600px]">
      <div
        className="absolute -left-36 rotate-[20deg] lg:rotate-0 lg:-left-80 -top-40 lg:top-40 bg-cover bg-no-repeat -scale-x-50 scale-y-50 lg:-scale-x-100 lg:scale-y-100"
        style={{
          backgroundImage:
            "url(" + require("../../assets/ghost_miner.png") + ")",
          width: 1216 / 4,
          height: 1415 / 4,
        }}
      />
      <div
        className="absolute rotate-[-15deg] lg:rotate-0 -right-40 -bottom-44 lg:-right-96 lg:top-64 bg-cover bg-no-repeat scale-50 lg:scale-100"
        style={{
          backgroundImage:
            "url(" + require("../../assets/knight_character.png") + ")",
          width: 974 / 3,
          height: 963 / 3,
        }}
      />
      {success ? (
        <Success />
      ) : (
        <>
          <Link className="self-end" to={DefaultRoutes.landingPage}>
            <Title className="text-white">X</Title>
          </Link>
          <span className="text-4xl sm:text-6xl mt-2 font-bold self-center text-[#FFD966]">
            Beta Sign Up
          </span>
          <Paragraph className="text-[#B89C49] text-center">
            Enter your email address to let us know your interest and to receive
            updates about the Beta.
          </Paragraph>
          <hr className="h-[2px] bg-[#B89C49] border-none my-2" />
          <span className="text-xl font-bold mt-4 mb-2 text-[#FFD966]">
            Email
          </span>
          <FormInput
            name="email"
            placeholder={"Email"}
            value={email}
            onChange={(value) => {
              setEmail(value);
              if (!!emailError && !!value.match(/.+@.+\..+/)) {
                setEmailError("");
              }
            }}
            isFirst
            isLast
            className="max-h-[38px]"
            errorMessage={emailError}
            onBlur={() => {
              if (!!email && !email.match(/.+@.+\..+/)) {
                setEmailError("Please enter a valid email address.");
              } else {
                setEmailError("");
              }
            }}
          />
          <span className="text-xl font-bold mt-4 text-[#FFD966]">
            Date of Birth
          </span>
          <div className="flex flex-row mt-2 gap-2 mb-10">
            <FormInput
              name="year"
              placeholder="year"
              type="select"
              value={year}
              values={years}
              onChange={(value) => setYear(value)}
              isFirst
              isLast
            />
            <FormInput
              name="month"
              placeholder="month"
              type="select"
              value={month}
              values={months}
              onChange={(value) => setMonth(value)}
              isFirst
              isLast
            />
            <FormInput
              name="day"
              placeholder="day"
              type="select"
              value={day}
              values={createDays(month ?? "01", year)}
              onChange={(value) => setDay(value)}
              isFirst
              isLast
            />
          </div>
          <SignUpButton
            className="self-center"
            disabled={
              signingUp ||
              !email ||
              !year ||
              !month ||
              !day ||
              !email.match(/.+@.+\..+/)
            }
            onPress={() => {
              if (!signingUp && email && year && month && day) {
                signUp(email, year, month, day);
              }
            }}
          />
          {errorMessage && (
            <span className="mt-2 self-center text-error">{errorMessage}</span>
          )}
        </>
      )}
    </div>
  );
};

const Success = () => {
  return (
    <>
      <Link className="self-end mt-5" to={DefaultRoutes.landingPage}>
        <Title className="text-white">X</Title>
      </Link>
      <span className="text-4xl sm:text-6xl font-bold self-center text-[#FFD966] text-center">
        Thank you for your Interest!
      </span>
      <hr className="h-[2px] bg-[#B89C49] border-none my-10" />
      <span className="text-4xl sm:text-6xl font-bold self-center text-[#FFD966]">
        Follow us on:
      </span>
      <div className="flex flex-row gap-2 mr-3 mb-2 self-center mt-10 pb-10 flex-wrap">
        <IconYouTube />
        <IconTikTok />
        <IconInstagram />
        <IconTwitter />
        <IconFacebook />
        <IconDiscord />
      </div>
    </>
  );
};

const months = [
  { value: "", text: "Month" },
  { value: "01", text: "January" },
  { value: "02", text: "February" },
  { value: "03", text: "March" },
  { value: "04", text: "April" },
  { value: "05", text: "May" },
  { value: "06", text: "June" },
  { value: "07", text: "July" },
  { value: "08", text: "August" },
  { value: "09", text: "September" },
  { value: "10", text: "October" },
  { value: "11", text: "November" },
  { value: "12", text: "December" },
];

const years = [
  { value: "", text: "Year" },
  ...Array.from({ length: 100 }, (_, i) => {
    const year = new Date().getFullYear();
    return { value: (year - i).toString(), text: (year - i).toString() };
  }),
];

function createDays(
  month: string,
  year: string
): { value: string; text: string }[] {
  if (!month || !year) {
    return [{ value: "", text: "Day" }];
  }
  const monthIndex = months.findIndex((m) => m.value === month);
  const numDays = new Date(parseInt(year), monthIndex + 1, 0).getDate();
  return [
    { value: "", text: "Day" },
    ...Array.from({ length: numDays }, (_, i) => {
      const day = (i + 1).toString();
      return { value: day, text: day };
    }),
  ];
}

const useSignUp = () => {
  const [signingUp, setSigningUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const success = pathname.startsWith(DefaultRoutes.signUpSuccess);

  const signUp = async (
    email: string,
    year: string,
    month: string,
    day: string
  ) => {
    if (!email || !year || !month || !day) {
      return;
    }

    setSigningUp(true);

    const result = await executeSignUp(email, year, month, day);
    switch (result.type) {
      case "success":
        navigate(DefaultRoutes.signUpSuccess);
        break;
      case "unavailable":
        setErrorMessage(
          "Service is currently unavailable. Please try again later."
        );
        setSigningUp(false);
        break;
      case "error":
        setErrorMessage("Something went wrong. Please try again.");
        setSigningUp(false);
        break;
    }
  };

  return {
    signUp,
    signingUp,
    errorMessage,
    success,
  };
};

async function executeSignUp(
  email: string,
  year: string,
  month: string,
  day: string
): Promise<{ type: "success" } | { type: "unavailable" } | { type: "error" }> {
  const request = {
    email,
    year,
    month,
    day,
  };

  const response = await safeFetch(Endpoints.betaSignUp, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  if (!response) {
    return { type: "unavailable" };
  }

  return { type: "success" };
}
