import * as React from "react";

import { useTrackOnUnmount } from "../../tools/useTrackOnUnmount";
import { FormFields } from "../common/FormFields";
import { FormInput } from "../common/FormInput";
import { useTrackEvent } from "../utility/useTrackEvent";
import { SimpleModal } from "../common/SimpleModal";
import {
  ChangeEmailResponseError,
  useChangeEmailRequest,
} from "../../requests/useChangeEmailRequest";
import { ModalProps } from "../common/Modal";
type Props = ModalProps & { currentEmail: string };

export const ChangeEmailModal = (props: Props) => {
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState<
    "start" | "inProgress" | "success" | "error"
  >("start");
  const [errorMessage, setErrorMessage] = React.useState("");
  const trackEvent = useTrackEvent();
  useTrackOnUnmount("ChangeEmail_Closed");
  const changeEmail = useChangeEmail();

  const isSameEmail =
    !!email && email.toLowerCase() === props.currentEmail.toLowerCase();
  const isValid = !!email && /^(.+)@(.+)\.(.+)$/.test(email) && !isSameEmail;

  function submit(): void {
    trackEvent("ChangeEmail_SubmitClicked");

    setStatus("inProgress");

    changeEmail(email).then((result) => {
      if (result === "success") {
        setStatus("success");
      } else if (result === "emailAlreadyUsed") {
        setStatus("error");
        setErrorMessage(
          "The given email address is already associated with another account."
        );
      } else {
        setStatus("error");
        setErrorMessage("Failed to change email!");
      }
    });
  }

  return (
    <SimpleModal
      {...props}
      title="Change Email"
      buttonDisabled={
        status !== "success" && (!isValid || status === "inProgress")
      }
      buttonText={status === "success" ? "Ok" : "Change"}
      onButtonClick={status === "success" ? props.onExit : submit}
      errorMessage={status === "error" ? errorMessage : undefined}
    >
      {status === "success" ? (
        <p>A verification email has been sent to {email}!</p>
      ) : (
        <FormFields>
          <FormInput
            name={"email"}
            placeholder={"email"}
            value={email}
            onChange={(value) => setEmail(value)}
            readonly={status === "inProgress"}
            isFirst
            isLast
            errorMessage={
              isSameEmail
                ? "The given email address matches your currrent one."
                : undefined
            }
          />
        </FormFields>
      )}
    </SimpleModal>
  );
};

const useChangeEmail = () => {
  const doChangeEmailRequest = useChangeEmailRequest();

  return async (email: string) => {
    const result = await doChangeEmailRequest(email);

    if (result.error) {
      switch (result.error) {
        case ChangeEmailResponseError.EMailAlreadyUsed:
          return "emailAlreadyUsed";
        case ChangeEmailResponseError.MatchesOldEMail:
          return "matchesOldEmail";
        case ChangeEmailResponseError.InternalError:
          return "internalError";
      }
    }

    return "success";
  };
};
