import React, { useState, FormEvent } from "react";

import { FormFields } from "../common/FormFields";
import { useTrackEvent } from "../utility/useTrackEvent";
import { Endpoints } from "../../Endpoints";
import { safeFetch } from "../../tools/safeFetch";
import { useAuthContext } from "../../state/AuthContext";
import { FormInput } from "../common/FormInput";
import { Button } from "../common/Button";
import { Paragraph } from "../common/Paraprah";
import { ResetPasswordModal } from "./ResetPasswordModal";

export const LoginForm = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isResetPasswordVisible, setResetPasswordVisible] = useState(false);

  const trackEvent = useTrackEvent();

  function validateForm(): boolean {
    return user.length > 0 && password.length > 0;
  }

  function openResetPasswordModal(): void {
    trackEvent("Login_ResetPasswordClicked");

    setResetPasswordVisible(true);
  }

  const { login, loggingIn, errorMessage } = useLogin();

  async function handleSubmit(
    event: FormEvent<HTMLButtonElement>
  ): Promise<void> {
    event.preventDefault();
    login(user, password);
  }

  return (
    <div className="flex flex-col w-[300px]">
      <FormFields title="Login">
        <FormInput
          name="email"
          placeholder={"email"}
          value={user}
          onChange={(value) => setUser(value)}
          isFirst
          isLast
        />
        <FormInput
          className="mt-2"
          placeholder={"password"}
          value={password}
          onChange={(value) => setPassword(value)}
          type={"password"}
          onSubmit={() => login(user, password)}
          isFirst
          isLast
        />
      </FormFields>
      <Button
        className="mt-5"
        disabled={loggingIn || !validateForm()}
        onClick={handleSubmit}
      >
        Login
      </Button>
      {errorMessage && (
        <span className="mt-2 self-center text-error">{errorMessage}</span>
      )}
      <div className="flex flex-row">
        <Paragraph>
          <span
            className="small-link text-light"
            onClick={openResetPasswordModal}
          >
            Forgot password?
          </span>
        </Paragraph>
      </div>
      {isResetPasswordVisible && (
        <ResetPasswordModal
          mounted={isResetPasswordVisible}
          onExit={() => setResetPasswordVisible(false)}
          underlayClickExits
        />
      )}
    </div>
  );
};

const useLogin = () => {
  const trackEvent = useTrackEvent();
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setState } = useAuthContext();

  const login = async (user: string, password: string) => {
    trackEvent("Login_LoginClicked");

    if (!user || !password) {
      return;
    }

    setLoggingIn(true);

    const result = await executeLogin(user, password);
    switch (result.type) {
      case "success":
        setState({ type: "authenticated", token: result.token });
        break;
      case "invalidCredentials":
        setErrorMessage("Email and/or password incorrect.");
        break;
      case "unavailable":
        setErrorMessage("Server unavailable. Please try again later.");
        break;
      case "internalError":
        setErrorMessage("Server error. Please try again.");
        break;
    }

    setLoggingIn(false);
  };

  return {
    login,
    loggingIn,
    errorMessage,
  };
};

type LoginResult =
  | { type: "success"; token: string }
  | { type: "invalidCredentials" }
  | { type: "unavailable" }
  | { type: "internalError" };

async function executeLogin(
  username: string,
  password: string
): Promise<LoginResult> {
  const request = {
    username,
    password,
  };

  const response = await safeFetch(Endpoints.login, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  if (!response) {
    return { type: "unavailable" };
  }

  if (response.status === 403) {
    return { type: "invalidCredentials" };
  }

  const token = response.headers.get("Authorization");
  if (token) {
    return {
      type: "success",
      token,
    };
  }

  return { type: "internalError" };
}
