import { TwoColumnSection } from "./TwoColumnSection";

import section5TitleImage from "../../assets/combat_color.png";
import { TextList } from "../common/TextList";

export const CombatSection = () => {
  return (
    <TwoColumnSection
      reverseColumns
      scrollFrames={frames}
      titleImageSrc={section5TitleImage}
      titleImageAlt="Coming Soon"
      trackingContentType={"LandingPage_Section5Visible"}
    >
      <div className="text-dark md:text-light my-4 responsive-font-size">
        <TextList>
          <span>
            Destroy your enemies with <strong>powerful weapons</strong>.
          </span>

          <span>
            Balanced combat for <strong>competitive PVP</strong> play.
          </span>

          <span>
            Earn your title with the PVP <strong>Ranking system</strong>.
          </span>

          <span>
            <strong>Party up</strong> with friends to take on tough enemies.
          </span>

          <span>
            <strong>Magic</strong>, <strong>Range</strong> and{" "}
            <strong>Melee</strong> Combat to mix & match.
          </span>

          <span>
            <strong>Souls-like combat</strong> system. Real time and fast paced.
          </span>
        </TextList>
      </div>
    </TwoColumnSection>
  );
};

const frames = [
  require("../../assets/frames_combat/ezgif-frame-001.jpg"),
  require("../../assets/frames_combat/ezgif-frame-002.jpg"),
  require("../../assets/frames_combat/ezgif-frame-003.jpg"),
  require("../../assets/frames_combat/ezgif-frame-004.jpg"),
  require("../../assets/frames_combat/ezgif-frame-005.jpg"),
  require("../../assets/frames_combat/ezgif-frame-006.jpg"),
  require("../../assets/frames_combat/ezgif-frame-007.jpg"),
  require("../../assets/frames_combat/ezgif-frame-008.jpg"),
  require("../../assets/frames_combat/ezgif-frame-009.jpg"),
  require("../../assets/frames_combat/ezgif-frame-010.jpg"),
  require("../../assets/frames_combat/ezgif-frame-011.jpg"),
  require("../../assets/frames_combat/ezgif-frame-012.jpg"),
  require("../../assets/frames_combat/ezgif-frame-013.jpg"),
  require("../../assets/frames_combat/ezgif-frame-014.jpg"),
  require("../../assets/frames_combat/ezgif-frame-015.jpg"),
  require("../../assets/frames_combat/ezgif-frame-016.jpg"),
  require("../../assets/frames_combat/ezgif-frame-017.jpg"),
  require("../../assets/frames_combat/ezgif-frame-018.jpg"),
  require("../../assets/frames_combat/ezgif-frame-019.jpg"),
  require("../../assets/frames_combat/ezgif-frame-020.jpg"),
  require("../../assets/frames_combat/ezgif-frame-021.jpg"),
  require("../../assets/frames_combat/ezgif-frame-022.jpg"),
  require("../../assets/frames_combat/ezgif-frame-023.jpg"),
  require("../../assets/frames_combat/ezgif-frame-024.jpg"),
  require("../../assets/frames_combat/ezgif-frame-025.jpg"),
  require("../../assets/frames_combat/ezgif-frame-026.jpg"),
  require("../../assets/frames_combat/ezgif-frame-027.jpg"),
  require("../../assets/frames_combat/ezgif-frame-028.jpg"),
  require("../../assets/frames_combat/ezgif-frame-029.jpg"),
  require("../../assets/frames_combat/ezgif-frame-030.jpg"),
  require("../../assets/frames_combat/ezgif-frame-031.jpg"),
  require("../../assets/frames_combat/ezgif-frame-032.jpg"),
  require("../../assets/frames_combat/ezgif-frame-033.jpg"),
  require("../../assets/frames_combat/ezgif-frame-034.jpg"),
  require("../../assets/frames_combat/ezgif-frame-035.jpg"),
  require("../../assets/frames_combat/ezgif-frame-036.jpg"),
  require("../../assets/frames_combat/ezgif-frame-037.jpg"),
  require("../../assets/frames_combat/ezgif-frame-038.jpg"),
  require("../../assets/frames_combat/ezgif-frame-039.jpg"),
  require("../../assets/frames_combat/ezgif-frame-040.jpg"),
];
