import { createContext, useContext, useMemo, useState } from "react";
import { AccountInfo } from "./AccountInfo";

type AccountInfoContextType = {
  info: AccountInfo | null;
  setInfo: (info: AccountInfo | null) => void;
};
const AccountInfoContext = createContext<AccountInfoContextType>({
  info: null,
  setInfo: () => {},
});

export const useAccountInfoContext = () => {
  return useContext(AccountInfoContext);
};

export const AccountInfoContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [info, setInfo] = useState<AccountInfo | null>(null);

  const value: AccountInfoContextType = useMemo(() => {
    return {
      info,
      setInfo,
    };
  }, [info, setInfo]);

  return (
    <AccountInfoContext.Provider value={value}>
      {children}
    </AccountInfoContext.Provider>
  );
};
