export const DiscordSection = () => {
  return (
    <div
      className="relative flex flex-row items-center justify-center py-10"
      style={{
        backgroundImage: "url(" + require("../../assets/discord_bg.png") + ")",
      }}
    >
      <div
        className="absolute -left-5 md:left-5 -top-10 bg-contain bg-no-repeat z-10 scale-75 md:scale-100"
        style={{
          backgroundImage:
            "url(" + require("../../assets/bow_character.png") + ")",
          width: 1376 / 8,
          height: 1876 / 8,
        }}
      />
      <a
        href="http://discord.gg/cqDshSn"
        target="_blank"
        rel="noreferrer"
        className="flex flex-row items-center gap-5 md:gap-10 z-20 pl-28 sm:pl-0"
      >
        <span className="text-white text-xl sm:text-3xl font-bold">Join Our Discord</span>
        <div
          className="w-10 h-10 md:w-[64px] md:h-[64px] bg-cover inline-block"
          style={{
            backgroundImage:
              "url(" + require("../../assets/discord-2-128.png") + ")",
          }}
        />
      </a>
    </div>
  );
};
