import React from "react";

interface Props {
  hasShadow?: boolean;
  backgroundImageSrc?: string;
  topBorder?: boolean;
}

export const LandingPageSection = (props: React.PropsWithChildren<Props>) => {
  const style: React.CSSProperties = {};
  if (props.backgroundImageSrc) {
    style["backgroundImage"] = `url(${props.backgroundImageSrc})`;
    style["backgroundSize"] = "cover";
  }
  if (props.hasShadow) {
    // solid on top and bottom, transparent on sides
    // style["bo"] = "0px 0px 1px 0px rgba(0, 0, 0, 1)";
    style["boxShadow"] = "0px 0px 10px 0px rgba(0, 0, 0, 0.5)";
  }

  return (
    <div className={"relative p-4 sm:p-8 overflow-hidden md:border-b-8"} style={style}>
      {props.children}
    </div>
  );
};
