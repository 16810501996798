import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useTrackEvent } from "./useTrackEvent";

export const TrackedLink: React.FC<LinkProps & { trackingName: string }> = ({
  children,
  trackingName,
  ...linkProps
}) => {
  const trackEvent = useTrackEvent();

  return (
    <Link
      {...linkProps}
      onClick={() => {
        trackEvent(trackingName);
      }}
    >
      {children}
    </Link>
  );
};
