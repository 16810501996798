import { useContext } from "react";
import { createInputContext } from "./createInputContext";

type Values = {
  fullName: string;
  country: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

export const {
  context: BillingInputContext,
  Provider: BillingInputContextProvider,
} = createInputContext<Values>(
  {
    fullName: "",
    country: "US",
    address: "",
    city: "",
    state: "",
    zip: "",
  },
  {
    fullName: (value) =>
      /^\S+\s\S+$/.test(value)
        ? true
        : {
            validity: "potentiallyValid",
            errorMessage: "Please enter your full name.",
          },
    zip: (value, values) =>
      values.country !== "US" || /^[0-9]{5}$/.test(value)
        ? true
        : {
            validity: "potentiallyValid",
            errorMessage: "Please enter a valid zip code.",
          },
  }
);

export const useBillingInputContext = () => {
  return useContext(BillingInputContext);
};
