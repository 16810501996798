import * as React from "react";
import { Paragraph } from "./Paraprah";

type Props = React.PropsWithChildren<{
  title?: string;
}>;

export const FormFields = ({ title, children }: Props) => {
  const actualChildren: React.ReactNode[] = [];
  React.Children.toArray(children)
    .filter((it) => !!it)
    .forEach((child, index) => {
      if (index > 0) {
        // actualChildren.push(<InputSeparator key={"divider" + index} />);
      }
      actualChildren.push(child);
    });

  return (
    <>
      {!!title && <Paragraph className="text-light mt-0">{title}</Paragraph>}
      <div className="flex flex-col">{actualChildren}</div>
    </>
  );
};
