import { useEffect } from "react";
import { useTrackEvent } from "../components/utility/useTrackEvent";

export function useTrackOnUnmount(trackingName: string): void {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    return () => {
      trackEvent(trackingName);
    };
  }, [trackingName, trackEvent]);
}
