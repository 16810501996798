import { useCallback } from "react";
import { useAuthToken } from "../state/AuthContext";
import { safeFetch } from "../tools/safeFetch";

export const usePostRequest = () => {
  const token = useAuthToken();

  return useCallback(
    async (endpoint: string, request?: object, overrideAuthToken?: string) => {
      const authToken = overrideAuthToken ?? token ?? "";

      return safeFetch(endpoint, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: request ? JSON.stringify(request) : undefined,
      });
    },
    [token]
  );
};
